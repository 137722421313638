import FormulaSuggestionOptionContent from "./FormulaSuggestionOptionContent";
import FormulaSuggestionOptionTitle from "./FormulaSuggestionOptionTitle";

type FormulaSuggestionOptionsProps = {
    variableHintOptions: IFormulaFunction[];
    formulaHintOptions: IFormulaFunction[];
    onInsertFormula: (f: IFormulaFunction) => void;
};

export default function FormulaSuggestionOptions({
    variableHintOptions,
    formulaHintOptions,
    onInsertFormula,
}: FormulaSuggestionOptionsProps) {
    return (
        <div className="flex w-full flex-col">
            {variableHintOptions.length > 0 && (
                <>
                    <FormulaSuggestionOptionTitle title="Source Variables" />
                    {variableHintOptions.map((o) => (
                        <FormulaSuggestionOptionContent
                            key={`formula-${o.value.trim()}`}
                            label={o.label}
                            onClick={() => onInsertFormula(o)}
                        />
                    ))}
                </>
            )}
            {formulaHintOptions.length > 0 && (
                <>
                    <FormulaSuggestionOptionTitle title="Supported Formula" />
                    {formulaHintOptions.map((o) => (
                        <FormulaSuggestionOptionContent
                            key={`formula-${o.value.trim()}`}
                            label={o.label}
                            onClick={() => onInsertFormula(o)}
                        />
                    ))}
                </>
            )}
            {formulaHintOptions.length === 0 &&
                variableHintOptions.length === 0 && <p>No match found.</p>}
        </div>
    );
}
