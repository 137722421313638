export default function convertFormulaBuilderToString(
    builder: IFormulaBuilder,
): {
    formula: string;
    variables: IFormulaVariable[];
} {
    const variables: IFormulaVariable[] = [];

    function builderToString(b: IFormulaBuilder): string {
        // Type string and variable do not have children
        if (b.formulaFunction.type === "string") {
            // Check if string is already quoted.
            // Split into 2 tests to prevent mixed single and double quotes.
            if (
                /^'.*'$/.test(b.formulaFunction.value) ||
                /^".*"$/.test(b.formulaFunction.value)
            ) {
                return b.formulaFunction.value;
            }
            return `"${b.formulaFunction.value}"`;
        } else if (b.formulaFunction.type === "variable") {
            // Make sure variables do not contain space
            const trimmedVariable = `_${b.formulaFunction.value
                .replaceAll(" ", "_")
                .replaceAll(/[0-9]/g, (matched) => {
                    // Variable cannot contain number, package limitation
                    switch (matched) {
                        case "1":
                            return "a";
                        case "2":
                            return "b";
                        case "3":
                            return "c";
                        case "4":
                            return "d";
                        case "5":
                            return "e";
                        case "6":
                            return "f";
                        case "7":
                            return "g";
                        case "8":
                            return "h";
                        case "9":
                            return "i";
                        case "0":
                            return "j";
                    }
                    return "x";
                })
                .trim()}_`;
            variables.push({
                value: b.formulaFunction.value,
                name: trimmedVariable,
                valueType: "DATA_COLUMN",
            });
            return trimmedVariable;
        } else if (b.formulaFunction.type === "formula") {
            // For Type formula
            const children: string[] = [];
            if (b.children) {
                b.children.forEach((child) => {
                    const childString = builderToString(child);
                    if (childString) {
                        children.push(childString);
                    }
                });
            }
            return `${b.formulaFunction.value}(${children.join(",")})`;
        }
        // For other non-supported types
        return "";
    }

    const formula = builderToString(builder);

    return {
        formula,
        variables,
    };
}
