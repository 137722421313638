import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import {
    addEdge,
    applyEdgeChanges,
    Background,
    Connection,
    ConnectionState,
    ControlButton,
    Controls,
    Edge,
    EdgeChange,
    MarkerType,
    MiniMap,
    Node,
    NodeTypes,
    OnEdgesChange,
    OnEdgesDelete,
    Position,
    ReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useAppDispatch } from "hooks";
import React from "react";
import { setDataMapperFlowClearAction } from "store/dataMapperFlow/actions";
import DataMappedNode from "./DataMappedNode";
import SourceNode from "./SourceNode";
import TargetNode, { ITargetNodeType } from "./TargetNode";
import createDataMappedNode from "./utils/createDataMappedNode";
import createEdge from "./utils/createEdge";
import "./utils/style.css";

type DataMapperFlowProps = {
    schemaFields: IDataMappingField[];
    sourceFields: string[];
    style?: React.CSSProperties;
    onMappingChanged: (field: IDataMappingField) => void;
    fullsize?: boolean;
    onChangeFullSize?: () => void;
};

const topPadding = 50;
const leftPadding = 50;
const verticalGap = 150;
const horizontalGap = 240;
const mappingGap = 240;

const nodeTypes: NodeTypes = {
    sourceNode: SourceNode,
    targetNode: TargetNode,
    dataMappedNode: DataMappedNode,
};

export default function DataMapperFlow({
    schemaFields,
    sourceFields,
    style = {},
    onMappingChanged,
    fullsize = false,
    onChangeFullSize,
}: DataMapperFlowProps) {
    const dispatch = useAppDispatch();
    const [nodes, setNodes] = React.useState<Node[]>([]);
    const [edges, setEdges] = React.useState<Edge[]>([]);

    React.useEffect(() => {
        // On mount, clear previous stored values
        dispatch(setDataMapperFlowClearAction());
    }, [dispatch]);

    React.useEffect(() => {
        const _nodes: Node[] = [];
        const _edges: Edge[] = [];
        sourceFields.forEach((sf, index) => {
            const trimedName = sf.trim().replaceAll(" ", "");
            _nodes.push({
                id: `source-${trimedName}`,
                type: "sourceNode",
                sourcePosition: Position.Right,
                selectable: false,
                position: {
                    x: leftPadding,
                    y: topPadding + verticalGap * index,
                },
                data: {
                    label: sf,
                    column: sf,
                },
            });
        });
        schemaFields.forEach((df, index) => {
            const trimmedName = df.name.trim().replaceAll(" ", "");
            const targetNodeId = `target-${trimmedName}`;
            _nodes.push({
                id: targetNodeId,
                type: "targetNode",
                targetPosition: Position.Left,
                position: {
                    x: leftPadding + horizontalGap,
                    y: topPadding + verticalGap * index,
                },
                data: {
                    field: df,
                },
            });

            // Create Data Node for DATA_COLUMN type if it's already mapped to Source
            if (df.value && df.mappingType === "DATA_COLUMN") {
                const trimmedSourceName = df.value.trim().replaceAll(" ", "");
                const sourceNodeId = `source-${trimmedSourceName}`;

                const newEdge = createEdge({
                    id: `${sourceNodeId}->${targetNodeId}}`,
                    source: sourceNodeId,
                    target: targetNodeId,
                    sourceHandle: `handle-source-output`,
                    targetHandle: `handle-target-input`,
                });
                _edges.push(newEdge);

                // Create Data Mapped Node
                const dataNode = createDataMappedNode({
                    targetId: targetNodeId,
                    positionX: mappingGap,
                    sourceColumns: [df.value],
                    sourceIds: [sourceNodeId],
                    field: df,
                    onDataChange: onMappingChanged,
                });
                _nodes.push(dataNode);

                // Create data edge
                const dataEdge = createEdge({
                    id: `${targetNodeId}->${dataNode.id}`,
                    source: targetNodeId,
                    target: dataNode.id,
                    sourceHandle: `handle-target-output`,
                    targetHandle: `handle-mapped-input`,
                    deletable: true,
                });
                _edges.push(dataEdge);
            }

            // Create Data Node for FORMULA is it's already set
            if (df.mappingType === "FORMULA") {
                const sourceIds: string[] = [];

                if (df.sourceColumns && df.sourceColumns.length > 0) {
                    // Linked source to target
                    df.sourceColumns.forEach((col) => {
                        const trimmedSourceName = col
                            .trim()
                            .replaceAll(" ", "");
                        const sourceNodeId = `source-${trimmedSourceName}`;
                        sourceIds.push(sourceNodeId);
                    });
                }

                // Linked source to target
                sourceIds.forEach((sourceNodeId) => {
                    const newEdge = createEdge({
                        id: `${sourceNodeId}->${targetNodeId}}`,
                        source: sourceNodeId,
                        target: targetNodeId,
                        sourceHandle: `handle-source-output`,
                        targetHandle: `handle-target-input`,
                    });
                    _edges.push(newEdge);
                });

                // Create Data Mapped Node
                const dataNode = createDataMappedNode({
                    targetId: targetNodeId,
                    positionX: mappingGap,
                    sourceColumns: df.sourceColumns ?? [],
                    sourceIds: sourceIds,
                    field: df,
                    onDataChange: onMappingChanged,
                });
                _nodes.push(dataNode);
                // Create data edge
                const dataEdge = createEdge({
                    id: `${targetNodeId}->${dataNode.id}`,
                    source: targetNodeId,
                    target: dataNode.id,
                    sourceHandle: `handle-target-output`,
                    targetHandle: `handle-mapped-input`,
                    deletable: true,
                });
                _edges.push(dataEdge);
            }
        });
        setNodes(_nodes);
        setEdges(_edges);
    }, [onMappingChanged, schemaFields, sourceFields]);

    const onConnect = React.useCallback(
        (params: Edge | Connection) =>
            setEdges((eds) =>
                addEdge(
                    {
                        ...params,
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 20,
                            height: 20,
                            color: "#b1b1b7",
                        },
                    },
                    eds,
                ),
            ),
        [],
    );

    const onEdgesChange: OnEdgesChange = React.useCallback(
        (changes: EdgeChange<Edge>[]) =>
            setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges],
    );

    const onEdgesDelete: OnEdgesDelete = React.useCallback(
        (changes: Edge[]) => {
            changes.forEach((edge) => {
                const incomingNode = nodes.find((n) => n.id === edge.source);
                if (incomingNode?.type === "sourceNode") {
                    // On Edge Deletion of Source->Target, update Target field to remove value
                    const targetNode = nodes.find(
                        (n) => n.id === edge.target && n.type === "targetNode",
                    ) as ITargetNodeType;
                    if (targetNode) {
                        const dataField = targetNode.data.field;
                        onMappingChanged({
                            ...dataField,
                            value:
                                dataField.mappingType === "DATA_COLUMN"
                                    ? ""
                                    : dataField.value,
                            sourceColumns:
                                dataField.sourceColumns?.filter(
                                    (c) => c !== incomingNode.data.column,
                                ) ?? [],
                        });
                    }
                } else if (incomingNode?.type === "targetNode") {
                    // On Edge Deletion of Target->Data, update Target field to remove value
                    const targetNode = incomingNode as ITargetNodeType;
                    const dataField = targetNode.data.field;
                    onMappingChanged({
                        ...dataField,
                        mappingType: "DATA_COLUMN",
                        value: "",
                        sourceColumns:
                            dataField.sourceColumns?.filter(
                                (c) => c !== incomingNode.data.column,
                            ) ?? [],
                        // Remove builder
                        variables: [],
                        builder: undefined,
                    });
                }
            });
        },
        [nodes, onMappingChanged],
    );

    const onConnectEnd = React.useCallback(
        (
            event: MouseEvent | TouchEvent,
            connectionState: Omit<ConnectionState, "inProgress">,
        ) => {
            if (
                // Drop TargetNode into anywhere on the canvas
                !connectionState.isValid &&
                connectionState.fromNode?.type === "targetNode"
            ) {
                const targetNode = connectionState.fromNode;
                if (targetNode?.data?.field) {
                    const dataField = targetNode.data
                        .field as IDataMappingField;
                    const newField: IDataMappingField = {
                        ...dataField,
                        mappingType: "FORMULA",
                        value: "",
                    };
                    onMappingChanged(newField);
                }
            }

            if (
                connectionState.isValid &&
                connectionState.fromNode?.type === "sourceNode" &&
                connectionState.toNode?.type === "targetNode"
            ) {
                const sourceNode = connectionState.fromNode;
                const targetNode = connectionState.toNode;
                if (targetNode?.data?.field) {
                    const dataField = targetNode.data
                        .field as IDataMappingField;
                    const sourceColumn: string = sourceNode.data
                        .column as string;
                    if (
                        !dataField.sourceColumns ||
                        dataField.sourceColumns.length === 0
                    ) {
                        // New source column
                        const newField: IDataMappingField = {
                            ...dataField,
                            mappingType: "DATA_COLUMN",
                            value: sourceColumn,
                            sourceColumns: [sourceColumn],
                        };
                        onMappingChanged(newField);
                    } else if (
                        dataField.sourceColumns.length > 0 &&
                        !dataField.sourceColumns.includes(sourceColumn)
                    ) {
                        // Not within source columns
                        const newField: IDataMappingField = {
                            ...dataField,
                            mappingType: "FORMULA",
                            value: "",
                            sourceColumns: [
                                ...dataField.sourceColumns,
                                sourceColumn,
                            ],
                            // Clear previous builder data
                            variables: [],
                            builder: undefined,
                        };
                        onMappingChanged(newField);
                    }
                }
            }
        },
        [onMappingChanged],
    );

    return (
        <div style={{ ...flowContainerStyle, ...style }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                onConnect={onConnect}
                onEdgesChange={onEdgesChange}
                onEdgesDelete={onEdgesDelete}
                onConnectEnd={onConnectEnd}
                panOnScroll
            >
                <Background />
                <Controls
                    position="bottom-right"
                    showInteractive={false}
                    showFitView={false}
                    style={{ right: "220px" }}
                >
                    {onChangeFullSize && (
                        <ControlButton onClick={() => onChangeFullSize()}>
                            {fullsize ? (
                                <FullscreenExitOutlined />
                            ) : (
                                <FullscreenOutlined />
                            )}
                        </ControlButton>
                    )}
                </Controls>
                <MiniMap position="bottom-right" pannable zoomable />
            </ReactFlow>
        </div>
    );
}

const flowContainerStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    fontSize: "14px",
    fontFamily:
        "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
};
