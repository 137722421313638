import { ExportOutlined } from "@ant-design/icons";
import FormulaDictionary from "components/atoms/FormulaDictionary";
import React from "react";

type FormulaSuggestionOptionContentProps = {
    label?: string;
    children?: React.ReactNode;
    onClick?: () => void;
};

const formulaDefinitions = FormulaDictionary();

export default function FormulaSuggestionOptionContent({
    label,
    children,
    onClick,
}: FormulaSuggestionOptionContentProps) {
    const definition: IFunctionDefinition | undefined = React.useMemo(
        () =>
            label && formulaDefinitions.has(label)
                ? formulaDefinitions.get(label)
                : undefined,
        [label],
    );

    return (
        <div
            className="flex cursor-pointer flex-col items-start gap-1 py-2 pl-3 hover:bg-gray-100"
            onClick={onClick}
        >
            <div className="flex w-full flex-row items-center gap-2 pr-1">
                <p className="text-sm">{label}</p>
                {definition?.category && (
                    <span className="rounded-sm bg-purple-200 px-1 text-[8px]">
                        {definition.category}
                    </span>
                )}
            </div>
            {definition && (
                <div className="flex w-full flex-col items-start gap-1 pl-3 pr-1">
                    <p className="text-wrap text-xs font-normal text-gray-700">
                        {definition.description}
                    </p>
                    {definition.syntax && (
                        <div className="flex w-full flex-col gap-1 overflow-hidden text-wrap border border-slate-100 bg-slate-50 p-1 text-xs italic text-slate-700">
                            <p>
                                <strong>Syntax:</strong> {definition.syntax}
                            </p>
                            {definition.example && (
                                <p>
                                    <strong>Result:</strong>{" "}
                                    {definition.example}
                                </p>
                            )}
                        </div>
                    )}
                    {definition.href && (
                        <a
                            href={definition.href}
                            target="_blank"
                            rel="noreferrer"
                            className="px-1 text-[8px] text-slate-600 hover:bg-slate-200"
                        >
                            Read more <ExportOutlined />
                        </a>
                    )}
                </div>
            )}
            {children}
        </div>
    );
}
