import { FileSearchOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Image, Input, Row, Table } from "antd";
import Layout, { Content, Footer, Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import CypressTestIds from "../../../cypress/CypressTestIds";

import React, { useState } from "react";
import { Icon, Menu } from "semantic-ui-react";

interface PreviewTenancyCustomizationFieldProps {
    previewData: {
        organizationName: string;
        sloganFooter: string;
        colorHeaderFooter: string;
        colorNavigationBar: string;
        colorSelectedHeader: string;
        colorPrimaryButtons: string;
        colorForm: string;
        colorSecondaryButtons: string;
        colorSelectedRow: string;
        colorLabel: string;
        logo: string | null;
    };
    handleUpdatePreview: () => void;
}

const PreviewTenancyCustomization: React.FC<
    PreviewTenancyCustomizationFieldProps
> = ({ previewData, handleUpdatePreview }) => {
    const [selectedRowKey, setSelectedRowKey] = useState<string | null>("1");

    const data = [
        {
            key: "1",
            name: "John Brown",
            age: 32,
            address: "New York No. 1 Lake Park",
            isSelected: true,
        },
        {
            key: "2",
            name: "Jim Green",
            age: 42,
            address: "London No. 1 Lake Park",
            isSelected: false,
        },
    ];

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Age",
            dataIndex: "age",
            key: "age",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
    ];

    const rowClassName = (record: any) => {
        return record.key === selectedRowKey ? "selected-row" : "";
    };

    return (
        <div className="container h-full border-l border-gray-500 bg-white p-10 md:border-none">
            <Title level={2}>Preview</Title>
            <div className="flex items-center justify-center bg-gray-200 p-5">
                <Layout
                    className="page-layout overflow-hidden"
                    style={{
                        height: "auto",
                        minHeight: "400px",
                        width: "900px",
                    }}
                >
                    <Header className="layout-header">
                        <Menu
                            inverted
                            className="header-menu"
                            style={{
                                backgroundColor: previewData.colorNavigationBar,
                                color: "#fff",
                            }}
                        >
                            <Menu
                                inverted
                                className="header-menu"
                                style={{
                                    backgroundColor:
                                        previewData.colorNavigationBar,
                                    color: "#fff",
                                }}
                                data-testid={
                                    CypressTestIds.TENANCY_CUSTOMIZATION_PREVIEW_NAVIGATION_BAR
                                }
                            >
                                <Menu.Item>
                                    <Image
                                        src={
                                            previewData.logo
                                                ? previewData.logo
                                                : "/icons/header-menu/pretectum-logo.png"
                                        }
                                        width={50}
                                    />
                                    <div className="header-icon-label">
                                        {previewData.organizationName}
                                    </div>
                                </Menu.Item>
                            </Menu>
                            <Menu.Item
                                name="home"
                                style={{
                                    backgroundColor:
                                        previewData.colorSelectedHeader,
                                    color: "#fff",
                                }}
                                data-testid={
                                    CypressTestIds.TENANCY_CUSTOMIZATION_PREVIEW_SELECTED_HEADER
                                }
                            >
                                <Image src="/icons/header-menu/home.png" />
                                <div className="item-label">Home</div>
                            </Menu.Item>
                            <Menu.Item name="schema" active={false}>
                                <Image src="/icons/header-menu/schema.png" />
                                <div className="item-label">Schema</div>
                            </Menu.Item>
                            <Menu.Item name="relationships" active={false}>
                                <Icon name="boxes" />
                                <div className="item-label">Relationships</div>
                            </Menu.Item>
                            <Menu.Item name="search" active={false}>
                                <SearchOutlined />
                                <div className="item-label">Search</div>
                            </Menu.Item>
                            <Menu.Item
                                name="dataobjectduplicatesearchpage"
                                active={false}
                            >
                                <FileSearchOutlined />
                                <div className="item-label">
                                    Search Duplicates
                                </div>
                            </Menu.Item>
                        </Menu>
                    </Header>
                    <Content>
                        <div className="p-10">
                            <Title level={3} color={previewData.colorLabel}>
                                Test
                            </Title>
                            <Form
                                layout="vertical"
                                className="rounded p-4"
                                style={{
                                    backgroundColor: previewData.colorForm,
                                }}
                                data-testid={
                                    CypressTestIds.TENANCY_CUSTOMIZATION_PREVIEW_FORM_BACKGROUND
                                }
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 6 }}
                            >
                                <Form.Item
                                    label={
                                        <div
                                            className="w-full p-0 text-right"
                                            style={{
                                                color: previewData.colorLabel,
                                            }}
                                            data-testid={
                                                CypressTestIds.TENANCY_CUSTOMIZATION_PREVIEW_LABEL_COLOR
                                            }
                                        >
                                            Some Label
                                        </div>
                                    }
                                    name="backgroundColor"
                                >
                                    <Input
                                        disabled
                                        placeholder="Enter color (e.g., #ffffff)"
                                    />
                                </Form.Item>
                                <div>
                                    <Table
                                        dataSource={data}
                                        columns={columns}
                                        rowKey="id"
                                        pagination={false}
                                        rowClassName={(record) =>
                                            record.isSelected
                                                ? `selected-row bg-opacity-100 text-opacity-100`
                                                : ""
                                        }
                                        onRow={(record) => ({
                                            className: record.isSelected
                                                ? "selected-row"
                                                : "",
                                            "data-testid": record.isSelected
                                                ? `${CypressTestIds.TENANCY_CUSTOMIZATION_PREVIEW_SELECTED_ROW}`
                                                : `row-${record.key}`,
                                            style: record.isSelected
                                                ? {
                                                      backgroundColor:
                                                          previewData.colorSelectedRow,
                                                      color: previewData.colorLabel,
                                                  }
                                                : {},
                                        })}
                                    />
                                </div>
                                <Form.Item>
                                    <div className="mt-2 flex w-full flex-row gap-3">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{
                                                backgroundColor:
                                                    previewData.colorPrimaryButtons,
                                            }}
                                            data-testid={
                                                CypressTestIds.TENANCY_CUSTOMIZATION_PREVIEW_PRIMARY_BUTTONS
                                            }
                                        >
                                            Apply
                                        </Button>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{
                                                backgroundColor:
                                                    previewData.colorSecondaryButtons,
                                                color: "black",
                                            }}
                                            data-testid={
                                                CypressTestIds.TENANCY_CUSTOMIZATION_PREVIEW_SECONDARY_BUTTONS
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                    </Content>
                    <Footer
                        style={{
                            backgroundColor: previewData.colorHeaderFooter,
                            color: "#fff",
                        }}
                        data-testid={
                            CypressTestIds.TENANCY_CUSTOMIZATION_PREVIEW_HEADER_FOOTER
                        }
                    >
                        <Row>
                            <Col
                                span={15}
                                className="footer-center-align-items"
                            >
                                <span className="main-footer-caption">
                                    {previewData.sloganFooter ||
                                        "Your slogan goes here."}
                                </span>
                            </Col>
                            <Col span={3} className="footer-right-column">
                                <Button type="link">Pretectum</Button>
                            </Col>
                            <Col span={3} className="footer-right-column">
                                <Button type="link">Documentation</Button>
                            </Col>
                            <Col span={3} className="footer-right-column">
                                <Button type="link">Privacy</Button>
                            </Col>
                        </Row>
                    </Footer>
                </Layout>
            </div>
            <Button
                type="primary"
                className="mt-4"
                data-testid={
                    CypressTestIds.TENANCY_CUSTOMIZATION_GENERATE_PREVIEW_BUTTON
                }
                onClick={handleUpdatePreview}
            >
                Update Preview
            </Button>
        </div>
    );
};

export default PreviewTenancyCustomization;
