import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Modal, Button } from "antd";
import DataObjectUnobfuscationLoginModal from 'components/organisms/DataObjectUnobfuscationLoginModal';
import ReadOnlyFormBuilder from 'components/molecules/ReadOnlyFormBuilder';

const DataObjectUnobfuscation = ({ schemaFields, selectedDataObject, onSave, readOnly }) => {
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isDataModalVisible, setIsDataModalVisible] = useState(false);
    
    const inactivityTimeout = 60000; // 60 seconds
    const inactivityTimerRef = useRef(null);    

    const clearInactivityTimer = useCallback(() => {
        if (inactivityTimerRef.current) {
            clearTimeout(inactivityTimerRef.current);
            inactivityTimerRef.current = null;
        }
    }, []);

    const startInactivityTimer = useCallback(() => {
        clearInactivityTimer();
        inactivityTimerRef.current = setTimeout(() => {
            if (isDataModalVisible) {
                closeDataModal();
            }
        }, inactivityTimeout);
    }, [clearInactivityTimer, isDataModalVisible, inactivityTimeout]);

    useEffect(() => {
        if (isDataModalVisible) {
            startInactivityTimer();

            const resetInactivityTimer = () => {
                if (isDataModalVisible) {
                    startInactivityTimer();
                }
            };
            document.addEventListener("mousemove", resetInactivityTimer);
            document.addEventListener("click", resetInactivityTimer);

            return () => {
                document.removeEventListener("mousemove", resetInactivityTimer);
                document.removeEventListener("click", resetInactivityTimer);
                clearInactivityTimer();
            };
        }
    }, [isDataModalVisible, startInactivityTimer, clearInactivityTimer]);

    const handleLoginSuccess = () => {
        setIsLoginModalVisible(false);
        setIsDataModalVisible(true);
    };

    const closeDataModal = useCallback(() => {
        setIsDataModalVisible(false);
    }, []);

    const handleLoginCancel = () => {
        setIsLoginModalVisible(false);
    };

    const handleOpenLoginModal = () => {
        setIsLoginModalVisible(true);
    };

    const handleSave = (updatedFields) => {
        if (onSave && selectedDataObject) {
            const payload = {
                ...updatedFields,
                version: selectedDataObject.version, 
            };
            onSave(payload);
        }
    };
    return (
        <>
            <Button onClick={handleOpenLoginModal}>Reveal</Button>

            <DataObjectUnobfuscationLoginModal
                visible={isLoginModalVisible}
                onCancel={handleLoginCancel}
                onLoginSuccess={handleLoginSuccess}
            />

            <Modal
                visible={isDataModalVisible && !!selectedDataObject}
                title="Data Record Preview"
                onCancel={closeDataModal}
                cancelText="Close"
                centered
                destroyOnClose
                width="50%"
                footer={null}
            >
                {selectedDataObject && (
                    <ReadOnlyFormBuilder
                        selectedDataObject={selectedDataObject}
                        schemaFields={schemaFields}
                        onSave={handleSave}
                        readOnly={readOnly}
                    />
                )}
            </Modal>
        </>
    );
};

export default DataObjectUnobfuscation;
