import {
    GET_DATA_TAGS_SUCCESS,
    GET_DATA_TAGS_FAILURE,

    CREATE_DATA_TAG_SUCCESS,
    CREATE_DATA_TAG_FAILURE,

    GET_DATA_TAG_BY_ID_SUCCESS,
    GET_DATA_TAG_BY_ID_FAILURE,

    GET_DATA_TAGS_BY_BUSINESS_AREA_ID_SUCCESS,
    GET_DATA_TAGS_BY_BUSINESS_AREA_ID_FAILURE,

    GET_USER_BUSINESS_AREA_DATA_TAGS_SUCCESS,
    GET_USER_BUSINESS_AREA_DATA_TAGS_FAILURE,

    DELETE_DATA_TAG_SUCCESS,
    DELETE_DATA_TAG_FAILURE,

    MULTI_DELETE_DATA_TAG_SUCCESS,
    MULTI_DELETE_DATA_TAG_FAILURE,

    UPDATE_DATA_TAG_SUCCESS,
    UPDATE_DATA_TAG_FAILURE,

    GET_USER_DATA_TAG_PERMISSION_SUCCESS,
    GET_USER_DATA_TAG_PERMISSION_FAILURE,

    REORDER_DATA_TAGS_SUCCESS,
    REORDER_DATA_TAGS_FAILURE,
    REORDER_DATA_TAGS_REQUEST,
    MARK_TAGS_PROCESSING,
    DND_TAG,
    SET_VIEW_STATE
} from "./actions";

const initialState = {  
    getDataTagsResult: {
        success: true
    },
    dataTagsResult: {
        Items: []
    },
    createDataTagResult: {
        success: true
    },
    getDataTagByIdResult: {
        success: true
    },
    dataTag: null,
    getDataTagsByBusinessAreaIdResult: {
        success: true,
        businessAreaId: null
    },
    dataTagsByBusinessAreaIdResult: {
        Items: []
    },
    getUserBusinessAreaDataTagsResult: {
        success: true
    },
    userBusinessAreaDataTags: {
        Items: []
    },
    deleteDataTagResult: {
        success: true
    },
    updateDataTagResult: {
        success: true
    },
    getUserDataTagPermissionResult: {
        success: true
    },
    reorderDataTagsResult: {
        success: true
    }, 
    multiDeleteDataTagsResult: {
        success: true
    } ,  
    permission: null,
    dndQueue: [],
    processingQueue: [],
    previousState:[],
    ViewState:'list',
    isUpdating: false
};

export default (state = initialState, action) => {
    let dataTagsResult = {
        Items: []
    };
    switch (action.type) {
        case GET_DATA_TAGS_SUCCESS:
            dataTagsResult = action.payload.dataTagsResult;
            return { ...state, getDataTagsResult: { success: true }, dataTagsResult };
        case GET_DATA_TAGS_FAILURE:
            return {
                ...state, getDataTagsResult: { success: false, ...action.payload.error },
                dataTagsResult: {
                    Items: []
                }
            };
        case CREATE_DATA_TAG_SUCCESS:
            return { ...state, createDataTagResult: { success: true } };
        case CREATE_DATA_TAG_FAILURE:
            return { ...state, createDataTagResult: { success: false, ...action.payload.error } };
        case GET_DATA_TAG_BY_ID_SUCCESS:
            return { ...state, getDataTagByIdResult: { success: true }, dataTag: action.payload.dataTag };
        case GET_DATA_TAG_BY_ID_FAILURE:
            return { ...state, getDataTagByIdResult: { success: false, ...action.payload.error }, dataTag: null };
        case GET_DATA_TAGS_BY_BUSINESS_AREA_ID_SUCCESS:
            if (action.payload.result && action.payload.result.Items) {
                action.payload.result.ItemsDict = {};
                for (let item of action.payload.result.Items) {
                    action.payload.result.ItemsDict[item.tagId] = item;
                }
            }
            return { ...state, getDataTagsByBusinessAreaIdResult: { success: true, businessAreaId: action.payload.businessAreaId }, dataTagsByBusinessAreaIdResult: action.payload.result };
        case GET_DATA_TAGS_BY_BUSINESS_AREA_ID_FAILURE:
            return { ...state, getDataTagsByBusinessAreaIdResult: { success: false, businessAreaId: action.payload.businessAreaId, ...action.payload.error }, dataTagsByBusinessAreaIdResult: { Items: [] } };
        case GET_USER_BUSINESS_AREA_DATA_TAGS_SUCCESS:
            return { ...state, getUserBusinessAreaDataTagsResult: { success: true }, userBusinessAreaDataTags: action.payload.result };
        case GET_USER_BUSINESS_AREA_DATA_TAGS_FAILURE:
            return { ...state, getUserBusinessAreaDataTagsResult: { success: false, ...action.payload.error }, userBusinessAreaDataTags: { Items: [] } };
        case DELETE_DATA_TAG_SUCCESS:
            dataTagsResult = { ...state.dataTagsResult };
            dataTagsResult.Items = state.dataTagsResult.Items.filter(dataTag => dataTag.tagId !== action.payload.tagId);
            return { ...state, deleteDataTagResult: { success: true }, dataTagsResult };
        case DELETE_DATA_TAG_FAILURE:
            return { ...state, deleteDataTagResult: { success: false, ...action.payload.error } };
        case MULTI_DELETE_DATA_TAG_SUCCESS:
            console.log('MULTI_DELETE_DATA_TAG_SUCCESS', action.payload.tagIds);
            dataTagsResult = { ...state.dataTagsResult };
            dataTagsResult.Items = state.dataTagsResult.Items.filter(dataTag => !action.payload.tagIds.some(tag => tag.tagId === dataTag.tagId));
            return { ...state, multiDeleteDataTagsResult: { success: true }, dataTagsResult };
        case MULTI_DELETE_DATA_TAG_FAILURE:
            return { ...state, multiDeleteDataTagsResult: { success: false, ...action.payload.error } };    
        case UPDATE_DATA_TAG_SUCCESS:
            return { ...state, updateDataTagResult: { success: true } };
        case UPDATE_DATA_TAG_FAILURE:
            return { ...state, updateDataTagResult: { success: false, ...action.payload.error } };
        case GET_USER_DATA_TAG_PERMISSION_SUCCESS:
            return { ...state, getUserDataTagPermissionResult: { success: true }, permission: action.payload.permission };
        case GET_USER_DATA_TAG_PERMISSION_FAILURE:
            return { ...state, getUserDataTagPermissionResult: { success: false, ...action.payload.error }, permission: null };
        case DND_TAG:
            // console.log("DND_TAG action received:", action.payload); 
            const { tagId, sortOrder, oldSortOrder } = action.payload.datatag;
            const updatedItems = state.dataTagsResult.Items.map(tag => 
                tag.tagId === tagId ? { ...tag, sortOrder: sortOrder } : tag
            );
            const sortedItems = updatedItems.sort((a, b) => a.sortOrder - b.sortOrder);
            return { ...state, dndQueue: [...state.dndQueue, action.payload.datatag],
                previousState: [...state.dataTagsResult.Items],
                dataTagsResult:{...state.dataTagsResult,Items:sortedItems} };
        case REORDER_DATA_TAGS_REQUEST:
            // console.log("Datattagreq action received:", action.params); 
                return {
                    ...state,
                    previousState: [...state.dataTagsResult.Items], 
                    dndQueue: [...state.dndQueue, ...action.params.dataTags], 
                    isUpdating: true,
                };
            
        case REORDER_DATA_TAGS_SUCCESS:
                return {
                    ...state,
                    dndQueue: state.dndQueue.filter(item => 
                        !state.processingQueue.find(pItem => 
                            pItem.tagId === item.tagId && 
                            pItem.newSortOrder === item.newSortOrder
                        )
                    ),
                    processingQueue: [],
                    isUpdating: false,
                    reorderDataTagsResult: { success: true },
                };
            
        case REORDER_DATA_TAGS_FAILURE:
                return {
                    ...state,
                    dataTagsResult: { 
                        Items: state.previousState 
                    }, 
                    processingQueue: [], 
                    isUpdating: false,
                    reorderDataTagsResult: { 
                        success: false, 
                        error: action.payload.error || "Reordering failed due to an unknown error"
                    },
                };
        case MARK_TAGS_PROCESSING:
                    return {
                        ...state,
                        processingQueue: action.payload.tags,
                        isUpdating: true
                    };
        case SET_VIEW_STATE:
            return {
                ...state,
                ViewState: action.payload.viewState
            };
        default:
            return state;
    }
};
