import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children?: ReactNode;
    fallback?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                this.props.fallback ?? (
                    <div className="flex w-full flex-1 flex-col items-center justify-center gap-4">
                        <ExclamationCircleOutlined className="text-2xl text-red-600" />
                        <h1>Sorry.. there was an error</h1>
                    </div>
                )
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
