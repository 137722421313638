export const GET_KEY_REQUEST = "GET_KEY_REQUEST";
export const GET_KEY_SUCCESS = "GET_KEY_SUCCESS";
export const GET_KEY_FAILURE = "GET_KEY_FAILURE";

export const GET_KEY_KEYTYPE_REQUEST = "GET_KEY_KEYTYPE_REQUEST";
export const GET_KEY_KEYTYPE_SUCCESS = "GET_KEY_KEYTYPE_SUCCESS";
export const GET_KEY_KEYTYPE_FAILURE = "GET_KEY_KEYTYPE_FAILURE";

export const GET_KEYBYID_REQUEST = "GET_KEYBYID_REQUEST";
export const GET_KEYBYID_SUCCESS = "GET_KEYBYID_SUCCESS";
export const GET_KEYBYID_FAILURE = "GET_KEYBYID_FAILURE";

export const CREATE_KEY_REQUEST = "CREATE_KEY_REQUEST";
export const CREATE_KEY_SUCCESS = "CREATE_KEY_SUCCESS";
export const CREATE_KEY_FAILURE = "CREATE_KEY_FAILURE";

export const UPDATE_KEY_REQUEST = "UPDATE_KEY_REQUEST";
export const UPDATE_KEY_SUCCESS = "UPDATE_KEY_SUCCESS";
export const UPDATE_KEY_FAILURE = "UPDATE_KEY_FAILURE";

export const DELETE_KEY_REQUEST = "DELETE_KEY_REQUEST";
export const DELETE_KEY_SUCCESS = "DELETE_KEY_SUCCESS";
export const DELETE_KEY_FAILURE = "DELETE_KEY_FAILURE";

export const GET_USER_SYSTEM_KEY_PERMISSION_REQUEST = "GET_USER_SYSTEM_KEY_PERMISSION_REQUEST";
export const GET_USER_SYSTEM_KEY_PERMISSION_SUCCESS = "GET_USER_SYSTEM_KEY_PERMISSION_SUCCESS";
export const GET_USER_SYSTEM_KEY_PERMISSION_FAILURE = "GET_USER_SYSTEM_KEY_PERMISSION_FAILURE";

export const getKeysRequest= () => ({
    type: GET_KEY_REQUEST
})
export const  getKeysSuccess= (keys) => ({
    type: GET_KEY_SUCCESS,
    payload: {keys}
});
export const getKeysFailure= (error) => ({
    type: GET_KEY_FAILURE,
    payload: {error}
});

export const getKeyByIdRequest=(keyId)=>({
    type: GET_KEYBYID_REQUEST,
    payload: {keyId}
})
export const getKeyByIdSuccess= (key) => ({
    type: GET_KEYBYID_SUCCESS,
    payload: {key}
})
export const getKeyByIdFailure= (error) => ({
    type: GET_KEYBYID_FAILURE,
    payload: {error}
})

export const getKeyByTypeRequest= (keyType) => ({
    type: GET_KEY_KEYTYPE_REQUEST,
    payload: {keyType}
})
export const getKeyByTypeSuccess= (key) => ({
    type: GET_KEY_KEYTYPE_SUCCESS,
    payload: {key}
})
export const getKeyByTypeFailure= (error) => ({
    type: GET_KEY_KEYTYPE_FAILURE,
    payload: {error}
})

export const getUserSystemKeyPermissionRequest= () => ({
    type: GET_USER_SYSTEM_KEY_PERMISSION_REQUEST
})
export const getUserSystemKeyPermissionSuccess= (permissions) => ({
    type: GET_USER_SYSTEM_KEY_PERMISSION_SUCCESS,
    payload: {
        permissions
    }
})
export const getUserSystemKeyPermissionFailure= (error) => ({
    type: GET_USER_SYSTEM_KEY_PERMISSION_FAILURE,
    payload: {
        error
    }
})
export const createKeyRequest= (keyData) => ({
    type: CREATE_KEY_REQUEST,
    payload: {keyData}
})
export const createKeySuccess= (keys) => ({
    type: CREATE_KEY_SUCCESS,
    payload: {keys}
})
export const createKeyFailure= (error) => ({
    type: CREATE_KEY_FAILURE,
    payload: {error}
})
export const updateKeyRequest= (keyData,keyId) => ({
    type: UPDATE_KEY_REQUEST,
    payload: {keyData,keyId}
})
export const updateKeySuccess= (keys) => ({
    type: UPDATE_KEY_SUCCESS,
    payload: {keys}
})
export const updateKeyFailure= (error) => ({
    type: UPDATE_KEY_FAILURE,
    payload: {error}
})
export const deleteKeyRequest= (keyId) => ({
    type: DELETE_KEY_REQUEST,
    payload: {keyId}
})
export const deleteKeySuccess= (keyId) => ({
    type: DELETE_KEY_SUCCESS,
    payload: {keyId}
})
export const deleteKeyFailure= (error) => ({
    type: DELETE_KEY_FAILURE,
    payload: {error}
})