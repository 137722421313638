import { PlusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import mergeClassNames from "common/mergeClassNames";
import React from "react";
import FormulaSuggestionInput from "../FormulaSuggestionInput";

type FormulaPresenterProps = {
    builder?: IFormulaBuilder;
    sourceColumns?: string[];
    onSelect: (selected: IFormulaFunction, parentId?: string) => void;
    onEdit: (edited: IFormulaFunction) => void;
};

const TooltipColors = {
    formula: "violet",
    variable: "cyan",
    string: "yellow",
};

const TooltipLabel = {
    formula: "Formula",
    variable: "Variable",
    string: "Constant",
};

export default function FormulaPresenter({
    builder,
    sourceColumns = [],
    onSelect,
    onEdit,
}: FormulaPresenterProps) {
    const [hovered, setHovered] = React.useState<boolean>(false);
    const [showInput, setShowInput] = React.useState<boolean>(false);
    const [editInput, setEditInput] = React.useState<boolean>(false);

    const labelClassName = React.useMemo(() => {
        let _bg = "border-gray-100 hover:border-gray-300";
        if (builder && builder.formulaFunction) {
            switch (builder.formulaFunction.type) {
                case "formula":
                    _bg = "border-violet-500 hover:bg-violet-300";
                    break;
                case "variable":
                    _bg = "border-cyan-500 hover:bg-cyan-100";
                    break;
                case "string":
                    _bg = "border-yellow-500 hover:bg-yellow-100";
                    break;
                default:
                    break;
            }
        }
        return _bg;
    }, [builder]);

    const handleSelect = React.useCallback(
        (selected: IFormulaFunction, parentId?: string) => {
            onSelect(selected, parentId);
            setShowInput(false);
        },
        [onSelect],
    );

    if (!builder || !builder.formulaFunction) {
        return (
            <FormulaSuggestionInput
                sourceColumns={sourceColumns}
                onSelect={handleSelect}
                onClose={() => setShowInput(false)}
            />
        );
    }

    return (
        <>
            {!editInput && (
                <Tooltip
                    title={
                        <p
                            className={mergeClassNames(
                                "m-0 p-0 text-xs",
                                builder.formulaFunction.type === "string"
                                    ? "text-gray-800"
                                    : "text-white",
                            )}
                        >
                            {TooltipLabel[
                                builder.formulaFunction.type
                            ].toUpperCase()}
                        </p>
                    }
                    placement="bottom"
                    color={TooltipColors[builder.formulaFunction.type]}
                    overlayInnerStyle={{ minHeight: "auto" }}
                >
                    <p
                        className={mergeClassNames(
                            "whitespace-nowrap border-b-2 px-1",
                            labelClassName,
                        )}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        onDoubleClick={() => {
                            setEditInput(true);
                            setHovered(false); // Remove any hover state
                        }}
                    >
                        {builder.formulaFunction.label}
                    </p>
                </Tooltip>
            )}
            {editInput && (
                <FormulaSuggestionInput
                    sourceColumns={sourceColumns}
                    onSelect={(edited) => {
                        onEdit({
                            ...builder.formulaFunction,
                            label: edited.label,
                            value: edited.value,
                            type: edited.type,
                        });
                        setEditInput(false);
                    }}
                    onClose={() => setEditInput(false)}
                    defaultValue={builder.formulaFunction.label}
                />
            )}
            {builder.formulaFunction.type === "formula" && (
                <span
                    className={mergeClassNames(
                        "border-b-2 border-transparent px-0",
                        hovered ? "bg-violet-300" : "bg-transparent",
                    )}
                >
                    (
                </span>
            )}
            {builder?.children &&
                builder.children.map((child) => (
                    <React.Fragment key={child.formulaFunction.id}>
                        <FormulaPresenter
                            builder={child}
                            sourceColumns={sourceColumns}
                            onSelect={handleSelect}
                            onEdit={onEdit}
                        />
                        <span
                            className={mergeClassNames(
                                "px-0",
                                hovered ? "bg-violet-300" : "bg-transparent",
                            )}
                        >
                            ,
                        </span>
                    </React.Fragment>
                ))}
            {builder.formulaFunction.type === "formula" && !showInput && (
                <div style={{ marginRight: "0" }}>
                    <button
                        className="mx-1 size-[24px] rounded-[4px] bg-sky-400 text-xs text-white hover:bg-sky-500"
                        onClick={() => setShowInput((prev) => !prev)}
                    >
                        <PlusOutlined />
                    </button>
                </div>
            )}
            {builder.formulaFunction.type === "formula" && showInput && (
                <FormulaSuggestionInput
                    sourceColumns={sourceColumns}
                    onSelect={(s) =>
                        handleSelect(s, builder.formulaFunction.id)
                    }
                    onClose={() => setShowInput(false)}
                />
            )}
            {builder.formulaFunction.type === "formula" && (
                <span
                    className={mergeClassNames(
                        "border-b-2 border-transparent px-0",
                        hovered ? "bg-violet-300" : "bg-transparent",
                    )}
                >
                    )
                </span>
            )}
        </>
    );
}
