import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { SystemConnectionList, LoadingOverlay, ProhibitedArea, ManageSystemConnectionAwsS3, ManageSystemConnectionJdbcPostgresql, OkCancelConfirmationBox, SystemConnectionItems } from "components";
import { SystemConnectionAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import flatten from 'flat';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { Row, Col } from "antd";

const getConnectionType = (type) => {
    if (type === "aws_s3") {
        return "awss3";
    } else if (type === "jdbc") {
        return "jdbc";
    }
}

class SystemConnections extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            busyMessage: "",
            permissionDenied: false,
            fetchingPermission: true,
            showSystemConnectionOptions: false
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        this.props.getSystemConnectionPermission();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting connections...",
                fetchingConnections: true
            });
            this.props.getSystemConnections();
        }
        else {
            if (this.props.connectionId) {
                if (this.props.connectionType === "awss3") {
                    this.props.getAwsS3Connection(this.props.connectionId);
                } else if (this.props.connectionType === "jdbc") {
                    this.props.getJdbcConnection(this.props.connectionId);
                }
            }
            if (this.props.action === "view" || this.props.action === "edit") {
                this.setState({
                    busy: true,
                    busyMessage: "Getting connection...",
                    fetchingConnection: true
                });
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: false,
                    busy: true,
                    fetchingConnection: true
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.onGetSystemConnectionsResultUpdate(prevProps);

        this.onCreateConnectionResultUpdate(prevProps);

        this.onGetConnectionResultUpdate(prevProps);

        this.onUpdateConnectionResultUpdate(prevProps);

        this.onDeleteConnectionResultUpdate(prevProps);

        this.onGetSystemConnectionPermissionResultUpdate(prevProps);
    }

    onGetSystemConnectionsResultUpdate = (prevProps) => {
        if (this.props.getSystemConnectionsResult && this.props.getSystemConnectionsResult !== prevProps.getSystemConnectionsResult) {
            if (!this.props.getSystemConnectionsResult.success) {
                if (this.props.getSystemConnectionsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get connections at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingConnections: false
            });
        }
    }

    onCreateConnectionResultUpdate = (prevProps) => {
        // For AWS S3
        if (this.props.createConnectionResult && this.props.createConnectionResult !== prevProps.createConnectionResult) {
            if (!this.props.createConnectionResult.success) {
                showError(this.props.createConnectionResult.message || "Connection cannot be created at this moment.");
                this.setState({
                    busy: false,
                    manageConnectionErrors: this.props.createConnectionResult.fields
                });
            }
            else {
                showSuccess("Connection created successfully.", () => {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                });
            }
        }

        // For JDBC
        if (this.props.createJdbcConnectionResult && this.props.createJdbcConnectionResult !== prevProps.createJdbcConnectionResult) {
            if (!this.props.createJdbcConnectionResult.success) {
                showError(this.props.createJdbcConnectionResult.message || "Connection cannot be created at this moment.");
                this.setState({
                    busy: false,
                    manageConnectionErrors: this.props.createJdbcConnectionResult.fields
                });
            }
            else {
                showSuccess("Connection created successfully.", () => {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                });
            }
        }
    }

    onGetConnectionResultUpdate = (prevProps) => {
        // For AWS S3
        if (this.props.getConnectionResult && this.props.getConnectionResult !== prevProps.getConnectionResult) {
            if (!this.props.getConnectionResult.success) {
                if (this.props.getConnectionResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get connection.");
                }
            }
            else {
                if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingConnection: false
            });
            if (this.props.systemConnectionListResult.Items.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }

        // For JDBC
        if (this.props.getJdbcConnectionResult && this.props.getJdbcConnectionResult !== prevProps.getJdbcConnectionResult) {
            if (!this.props.getJdbcConnectionResult.success) {
                if (this.props.getJdbcConnectionResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get connection.");
                }
            }
            else {
                if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingConnection: false
            });
            if (this.props.systemConnectionListResult.Items.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }
    }

    onUpdateConnectionResultUpdate = (prevProps) => {
        // For AWS S3
        if (this.props.updateConnectionResult && this.props.updateConnectionResult !== prevProps.updateConnectionResult) {
            if (!this.props.updateConnectionResult.success) {
                if (this.props.updateConnectionResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false,
                        permissionDenied: true
                    });
                }
                else {
                    this.setState({
                        busy: false,
                        manageConnectionErrors: this.props.updateConnectionResult.fields
                    });
                    showError("Could not able to update connection.");
                }
            }
            else {
                if (this.state.isActivateDeactivateConnection) {
                    showSuccess(`Connection ${this.state.connectionActive ? "activated" : "deactivated"} successfully.`);
                    this.setState({
                        busy: false,
                        isActivateDeactivateConnection: false,
                        connectionActive: false
                    });
                }
                else {
                    this.setState({
                        busyMessage: "Connection updated. Please wait..."
                    });
                    showSuccess("Connection updated successfully.", () => {
                        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                    });
                }
            }
        }

        // For JDBC
        if (this.props.updateJdbcConnectionResult && this.props.updateJdbcConnectionResult !== prevProps.updateJdbcConnectionResult) {
            if (!this.props.updateJdbcConnectionResult.success) {
                if (this.props.updateJdbcConnectionResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false,
                        permissionDenied: true
                    });
                }
                else {
                    this.setState({
                        busy: false,
                        manageConnectionErrors: this.props.updateJdbcConnectionResult.fields
                    });
                    showError("Could not able to update connection.");
                }
            }
            else {
                if (this.state.isActivateDeactivateConnection) {
                    showSuccess(`Connection ${this.state.connectionActive ? "activated" : "deactivated"} successfully.`);
                    this.setState({
                        busy: false,
                        isActivateDeactivateConnection: false,
                        connectionActive: false
                    });
                }
                else {
                    this.setState({
                        busyMessage: "Connection updated. Please wait..."
                    });
                    showSuccess("Connection updated successfully.", () => {
                        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                    });
                }
            }
        }
    }

    onDeleteConnectionResultUpdate = (prevProps) => {
        // For AWS S3
        if (this.props.deleteConnectionResult && this.props.deleteConnectionResult !== prevProps.deleteConnectionResult) {
            if (!this.props.deleteConnectionResult.success) {
                showError(this.props.deleteConnectionResult.message || "Connection cannot be deleted.");
            }
            else {
                showSuccess("Connection deleted successfully.");
            }
            this.setState({
                busy: false
            });
        }

        // For JDBC
        if (this.props.deleteJdbcConnectionResult && this.props.deleteJdbcConnectionResult !== prevProps.deleteJdbcConnectionResult) {
            if (!this.props.deleteJdbcConnectionResult.success) {
                showError(this.props.deleteJdbcConnectionResult.message || "Connection cannot be deleted.");
            }
            else {
                showSuccess("Connection deleted successfully.");
            }
            this.setState({
                busy: false
            });
        }
    }

    onGetSystemConnectionPermissionResultUpdate = (prevProps) => {
        if (this.props.getSystemConnectionPermissionResult && this.props.getSystemConnectionPermissionResult !== prevProps.getSystemConnectionPermissionResult) {
            if (!this.props.getSystemConnectionPermissionResult.success || !this.props.permission || !this.props.permission.canView) {
                this.setState({
                    permissionDenied: true
                });
            }
            this.setState({
                fetchingPermission: false
            });
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [];
            if (this.props.connectionType) {
                breadCrumbItems.push({
                    route: "",
                    text: this.props.connectionType
                });
            }
            if (this.props.action) {
                let connectionName = "";
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New connection"
                        });
                        break;
                    case "view":
                    case "edit":
                        connectionName = this.getConnectionName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: connectionName
                        }]);
                        break;
                    case "history":
                        connectionName = this.getConnectionName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: connectionName
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                        break;
                    default:           
                }
            }

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    getConnectionName = () => {
        let name = this.props.connectionId;
        if (this.props.systemConnectionListResult.Items && this.props.systemConnectionListResult.Items.length > 0) {
            let connection = this.props.systemConnectionListResult.Items.find(item => item.connectionId === this.props.connectionId);
            if (connection) {
                name = connection.name;
            }
        }
        else if (this.props.connection && this.props.connection.connectionId === this.props.connectionId) {
            name = this.props.connection.name;
        }
        return name;
    }

    showAddConnection = () => {
        this.setState({
            showSystemConnectionOptions: true
        });
    }

    showEditConnection = (connectionId, connectionType) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_SUB_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":subItem", getConnectionType(connectionType))
            .replace(":itemId", connectionId)
            .replace(":itemAction", "edit"));
    }

    showViewConnection = (connectionId, connectionType) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_SUB_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":subItem", getConnectionType(connectionType))
            .replace(":itemId", connectionId)
            .replace(":itemAction", "view"));
    }

    showConnectionHistory = (connectionId, connectionType) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_SUB_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":subItem", getConnectionType(connectionType))
            .replace(":itemId", connectionId)
            .replace(":itemAction", "history"));
    }

    cancelCreateConnection = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
    }

    createConnection = (connection) => {
        this.setState({
            busy: true,
            busyMessage: "Creating connection..."
        });
        this.props.createAwsS3Connection(connection);
    }

    createJdbcConnection = (connection) => {
        this.setState({
            busy: true,
            busyMessage: "Creating connection..."
        });
        this.props.createJdbcConnection(connection);
    }

    updateAwsS3Connection = (connectionId, updatedConnection) => {
        if (_.isEmpty(updatedConnection) === false) {
            this.setState({
                busy: true,
                busyMessage: "Updating connection..."
            });
            this.props.updateAwsS3Connection(connectionId, updatedConnection);
        }
        else {
            this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
        }
    }

    updateJdbcConnection = (connectionId, updatedConnection) => {
        if (_.isEmpty(updatedConnection) === false) {
            this.setState({
                busy: true,
                busyMessage: "Updating connection..."
            });
            this.props.updateJdbcConnection(connectionId, updatedConnection);
        }
        else {
            this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
        }
    }

    onActivateDeactivateConnection = (connection, active, connectionType) => {
        if (connectionType === "aws_s3") {
            this.setState({
                isActivateDeactivateConnection: true,
                connectionActive: active,
                busy: true,
                busyMessage: active ? "Activating connection..." : "Deactivating connection..."
            });
            this.props.updateAwsS3Connection(connection.connectionId, { version: connection.version, active });
        } else if (connectionType === "jdbc") {
            this.setState({
                isActivateDeactivateConnection: true,
                connectionActive: active,
                busy: true,
                busyMessage: active ? "Activating connection..." : "Deactivating connection..."
            });
            this.props.updateJdbcConnection(connection.connectionId, { version: connection.version, active });
        }
    }

    onConnectionValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.manageConnectionErrors) === false) {
            let manageConnectionErrors = { ...this.state.manageConnectionErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete manageConnectionErrors[key];
            }
            this.setState({
                manageConnectionErrors
            })
        }
    }

    showDeleteConnectionConfirmation = (connection) => {
        this.setState({
            deletedConnection: connection,
            showConnectionDeleteConfirmation: true,
            deleteConnectionConfirmMessage: `Are you sure, you want to delete '${connection.name}' connection?`
        })
    }

    onDeleteConnectionConfirmed = () => {
        if (this.state.deletedConnection.type === "aws_s3") {
            this.setState({
                busy: true,
                busyMessage: "Deleting connection..."
            });
            this.props.deleteAwsS3Connection(this.state.deletedConnection.connectionId);
        } else if (this.state.deletedConnection.type === "jdbc") {
            this.setState({
                busy: true,
                busyMessage: "Deleting connection..."
            });
            this.props.deleteJdbcConnection(this.state.deletedConnection.connectionId);
        }
        this.onDeleteConnectionCancel();
    }

    onDeleteConnectionCancel = () => {
        this.setState({
            deletedConnection: null,
            showConnectionDeleteConfirmation: false,
            deleteConnectionConfirmMessage: ""
        })
    }

    onItemClick = (item) => {
        this.setState({
            showSystemConnectionOptions: false
        });
        switch (item) {
            case "aws_s3":
                this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
                    .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
                    .replace(":itemId", "awss3")
                    .replace(":itemAction", "create"));
                break;
            case "jdbc":
                this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
                    .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
                    .replace(":itemId", "jdbc")
                    .replace(":itemAction", "create"));
                break;
            default:
                this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                    .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                break;
        }

    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingConnections || this.state.fetchingPermission);
        if (this.props.action === "view" || this.props.action === "edit") {
            isBusy = isBusy || this.state.fetchingConnection;
        }
        return isBusy;
    }

    getConnectionComponent = () => {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (this.state.showSystemConnectionOptions) {
            return <SystemConnectionItems onItemClick={this.onItemClick}></SystemConnectionItems>
        }
        if (!this.props.action) {
            return (
                <>
                    <OkCancelConfirmationBox
                        show={this.state.showConnectionDeleteConfirmation}
                        message={this.state.deleteConnectionConfirmMessage}
                        onCancel={this.onDeleteConnectionCancel}
                        onOk={this.onDeleteConnectionConfirmed}>
                    </OkCancelConfirmationBox>
                    {
                        !this.state.fetchingConnections
                            ?
                            <SystemConnectionList
                                permission={this.props.permission}
                                connectionList={this.props.systemConnectionListResult.Items}
                                onAddConnection={this.showAddConnection}
                                onViewConnection={this.showViewConnection}
                                onEditConnection={this.showEditConnection}
                                onDeleteConnection={this.showDeleteConnectionConfirmation}
                                onActivateDeactivateConnection={this.onActivateDeactivateConnection}
                                onViewConnectionHistory={this.showConnectionHistory}
                            />
                            :
                            <></>
                    }
                </>
            );
        }
        if (this.props.connectionType === "awss3") {
            switch (this.props.action.toLowerCase()) {
                case "create":
                    return <ManageSystemConnectionAwsS3
                        permission={this.props.permission}
                        action={this.props.action}
                        onSave={this.createConnection}
                        onCancel={this.cancelCreateConnection}
                        formErrors={this.state.manageConnectionErrors}
                        onValuesChanged={this.onConnectionValuesChanged}>
                    </ManageSystemConnectionAwsS3>;
                case "view":
                case "edit":
                    return !this.state.fetchingConnection
                        ?
                        (this.props.connection
                            ?
                            <>
                                <OkCancelConfirmationBox
                                    show={this.state.showRegenerateSecretConfirmation}
                                    message={this.state.regenerateSecretConfirmationMessage}
                                    onCancel={this.onRegenerateSecretCancel}
                                    onOk={this.onRegenerateSecretConfirmed}>
                                </OkCancelConfirmationBox>
                                <ManageSystemConnectionAwsS3
                                    permission={this.props.permission}
                                    action={this.props.action}
                                    onSave={this.updateAwsS3Connection}
                                    onCancel={this.cancelCreateConnection}
                                    onRegenerateConnectionSecret={this.showRegenerateSecretConfirmation}
                                    connection={this.props.connection}
                                    formErrors={this.state.manageConnectionErrors}
                                    onValuesChanged={this.onConnectionValuesChanged}>
                                </ManageSystemConnectionAwsS3>
                            </>
                            :
                            (this.props.getConnectionResult.success
                                ?
                                <Message floating size='big'>
                                    <Message.Header>Connection not found.</Message.Header>
                                    <p>The app you are looking for does not exist.</p>
                                </Message>
                                :
                                <></>
                            )
                        )
                        :
                        <></>;
                case "history":
                    return this.state.showHistory ? <SystemConnectionAudit connection={this.props.connection} onClose={this.cancelCreateConnection}></SystemConnectionAudit> : <></>
                default:
                    return <ProhibitedArea></ProhibitedArea>
            }
        } else if (this.props.connectionType === "jdbc") {
            switch (this.props.action.toLowerCase()) {
                case "create":
                    return <ManageSystemConnectionJdbcPostgresql
                        permission={this.props.permission}
                        action={this.props.action}
                        onSave={this.createJdbcConnection}
                        onCancel={this.cancelCreateConnection}
                        formErrors={this.state.manageConnectionErrors}
                        onValuesChanged={this.onConnectionValuesChanged}>
                    </ManageSystemConnectionJdbcPostgresql>;
                case "view":
                case "edit":
                    return !this.state.fetchingConnection
                        ?
                        (this.props.jdbcConnection
                            ?
                            <>
                                <OkCancelConfirmationBox
                                    show={this.state.showRegenerateSecretConfirmation}
                                    message={this.state.regenerateSecretConfirmationMessage}
                                    onCancel={this.onRegenerateSecretCancel}
                                    onOk={this.onRegenerateSecretConfirmed}>
                                </OkCancelConfirmationBox>
                                <ManageSystemConnectionJdbcPostgresql
                                    permission={this.props.permission}
                                    action={this.props.action}
                                    onSave={this.updateJdbcConnection}
                                    onCancel={this.cancelCreateConnection}
                                    onRegenerateConnectionSecret={this.showRegenerateSecretConfirmation}
                                    connection={this.props.jdbcConnection}
                                    formErrors={this.state.manageConnectionErrors}
                                    onValuesChanged={this.onConnectionValuesChanged}>
                                </ManageSystemConnectionJdbcPostgresql>
                            </>
                            :
                            (this.props.getJdbcConnectionResult.success
                                ?
                                <Message floating size='big'>
                                    <Message.Header>Connection not found.</Message.Header>
                                    <p>The app you are looking for does not exist.</p>
                                </Message>
                                :
                                <></>
                            )
                        )
                        :
                        <></>;
                case "history":
                    return this.state.showHistory ? <SystemConnectionAudit connection={this.props.jdbcConnection} onClose={this.cancelCreateConnection}></SystemConnectionAudit> : <></>
                default:
                    return <ProhibitedArea></ProhibitedArea>
            }
        }
        else {
            return <></>
        }
    }

    render() {
        return (
            <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.state.fetchingPermission ? this.getConnectionComponent() : <></>}
                </Col>
            </Row>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        systemConnectionListResult: state.systemConnection.systemConnectionListResult,
        getSystemConnectionsResult: state.systemConnection.getSystemConnectionsResult,
        createConnectionResult: state.systemConnectionAwsS3.createConnectionResult,
        getConnectionResult: state.systemConnectionAwsS3.getConnectionResult,
        connection: state.systemConnectionAwsS3.connection,
        createdConnection: state.systemConnectionAwsS3.createdConnection,
        updateConnectionResult: state.systemConnectionAwsS3.updateConnectionResult,
        deleteConnectionResult: state.systemConnectionAwsS3.deleteConnectionResult,
        createJdbcConnectionResult: state.systemConnectionJdbc.createConnectionResult,
        getJdbcConnectionResult: state.systemConnectionJdbc.getConnectionResult,
        jdbcConnection: state.systemConnectionJdbc.connection,
        createdJdbcConnection: state.systemConnectionJdbc.createdConnection,
        updateJdbcConnectionResult: state.systemConnectionJdbc.updateConnectionResult,
        deleteJdbcConnectionResult: state.systemConnectionJdbc.deleteConnectionResult,
        getSystemConnectionPermissionResult: state.systemConnection.getSystemConnectionPermissionResult,
        permission: state.systemConnection.userSystemConnectionPermission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSystemConnectionPermission: () => dispatch(actions.getSystemConnectionPermissionRequest()),
        getSystemConnections: () => dispatch(actions.getSystemConnectionsRequest()),
        createAwsS3Connection: (connection) => dispatch(actions.createAwsS3ConnectionRequest(connection)),
        getAwsS3Connection: (connectionId) => dispatch(actions.getAwsS3ConnectionRequest(connectionId)),
        updateAwsS3Connection: (connectionId, updatedConnection) => dispatch(actions.updateAwsS3ConnectionRequest(connectionId, updatedConnection)),
        deleteAwsS3Connection: (connectionId) => dispatch(actions.deleteAwsS3ConnectionRequest(connectionId)),
        createJdbcConnection: (connection) => dispatch(actions.createJdbcConnectionRequest(connection)),
        getJdbcConnection: (connectionId) => dispatch(actions.getJdbcConnectionRequest(connectionId)),
        updateJdbcConnection: (connectionId, updatedConnection) => dispatch(actions.updateJdbcConnectionRequest(connectionId, updatedConnection)),
        deleteJdbcConnection: (connectionId) => dispatch(actions.deleteJdbcConnectionRequest(connectionId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemConnections));