import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import LABELS from 'constants/labels';
import actions from 'store/actions';
import { KeysList, ManageKeys, OkCancelConfirmationBox, LoadingOverlay,ProhibitedArea } from 'components';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { useHistory, useParams } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';
import flatten from 'flat';
import SystemKeysAudit from './SystemKeysAudit';

const SystemKeys = (props) => {
    const [isbusy, setBusy] = useState(false);
    const [fetchingKeys, setFetchingKeys] = useState(true);
    const [busyMessage, setBusyMessage] = useState("");
    const [manageKeyErrors, setManageKeyErrors] = useState({});
    const [deletedKey, setDeletedKey] = useState(null);
    const [showKeyDeleteConfirmation, setShowKeyDeleteConfirmation] = useState(false);
    const [deleteKeyConfirmMessage, setDeleteKeyConfirmMessage] = useState("");
    const [showKeyHistory, setShowKeyHistory] = useState(false);
    const [keyData, setKeyData] = useState(null);
    const [fetchingPermissions, setFetchingPermissions] = useState(true);
    const [permissionDenied, setPermissionDenied] = useState(false);

    const history = useHistory();
    const { tenant, configurationItem, itemAction, itemId } = useParams();
    const prevProps = useRef(props);

    useEffect(() => {
        showBreadCrumbNavigationItems();
        props.getUserSystemKeyPermission();
        if (!itemAction) {
            setBusy(true);
            setBusyMessage("Getting Keys...");
            props.getKeys();
        } else {
            if (itemId) {
                props.getKeyById(itemId);
            }
            if (itemAction === "view" || itemAction === "edit") {
                setBusy(true);
                setBusyMessage("Getting Key...");
                // setFetchingKeys(true);
            }
            if(itemAction === "history"){
                setBusy(true);
                setBusyMessage("Getting Key History...");
                // console.log("isbusyvhai",isbusy)
                // setFetchingKeys(true);
                props.getKeyById(itemId);
                // props.getKeyById(itemId);
            }
        }
    }, [itemAction, itemId]);

    useEffect(() => {
        const prevGetKeysResult = prevProps.current.getKeysResult;
        const prevCreateKeyResult = prevProps.current.createKeyResult;
        const prevGetKeyByIdResult = prevProps.current.getKeyByIdResult;
        const prevUpdateKeyResult = prevProps.current.updateKeyResult;
        const prevDeleteKeyResult = prevProps.current.deleteKeyResult;
        const prevGetUserSystemKeyPermissionResult = prevProps.current.getUserSystemKeyPermissionResult;
        if (props.getKeysResult && props.getKeysResult !== prevGetKeysResult) {
            if (!props.getKeysResult.success) {
                if(props.getKeysResult.code === "PERMISSION_DENIED") {
                    setPermissionDenied(true);
                }
                else{
                    showError(props.getKeysResult.message||"Could not get keys at this moment.");
                }
            }
            setBusy(false);
            setBusyMessage("");
            setFetchingKeys(false);
        }

        if (props.createKeyResult && props.createKeyResult !== prevCreateKeyResult) {
            if (props.createKeyResult.success) {
                showSuccess("Key created successfully.");
                setTimeout(()=>{history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", tenant)
                    .replace(":configurationItem", configurationItem.toLowerCase()));},2000);
            }
            else if(!props.createKeyResult.success) {
                showError(props.createKeyResult.message||"Could not create key at this moment.");
                setManageKeyErrors(props.createKeyResult.fields);
            }
            setBusy(false);
            setBusyMessage("");
        }

        if (props.getKeyByIdResult && props.getKeyByIdResult !== prevGetKeyByIdResult) {
            if (!props.getKeyByIdResult.success) {
                if(props.getKeyByIdResult.code === "PERMISSION_DENIED") {
                    setPermissionDenied(true);
                }
                else{
                showError(props.getKeyByIdResult.message||"Could not get key details at this moment.");
                setManageKeyErrors(props.getKeyByIdResult.fields)
                }
            }
            else if(itemAction==="history" && props.getKeyByIdResult.success){
                setShowKeyHistory(true);
            }
            setBusy(false);
            setBusyMessage("");
        }

        if (props.updateKeyResult && props.updateKeyResult !== prevUpdateKeyResult) {
            if (props.updateKeyResult.success) {
                showSuccess("Key updated successfully.");
                setTimeout(()=>{history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", tenant)
                    .replace(":configurationItem", configurationItem.toLowerCase()))},2000);
            } 
            else if (!props.updateKeyResult.success) {
                if(props.updateKeyResult.code === "PERMISSION_DENIED") {
                    setPermissionDenied(true);
                }
                else{
                    showError(props.updateKeyResult.message||"Could not update key at this moment.");
                    setManageKeyErrors(props.updateKeyResult.fields);
                }
            }
            setBusy(false);
            setBusyMessage("");
        }

        if (props.deleteKeyResult && props.deleteKeyResult !== prevDeleteKeyResult) {
            if (props.deleteKeyResult.success) {

                showSuccess("Key deleted successfully.");
                setTimeout(() => {
                    props.getKeys();
                }, 800);
            } else { 
                if(props.deleteKeyResult.code === "PERMISSION_DENIED") {
                    setPermissionDenied(true);
                }
                else{
                    showError(props.deleteKeyResult.message||"Could not delete key at this moment.");
                }
            }
            setBusy(false);
            setBusyMessage("");
        }
        if(props.getUserSystemKeyPermissionResult && props.getUserSystemKeyPermissionResult !== prevGetUserSystemKeyPermissionResult) {
            console.log("permission result", props.getUserSystemKeyPermissionResult);
            console.log("peremissions",props.permission)
            if(!props.getUserSystemKeyPermissionResult.success) {
                setPermissionDenied(true);
                setFetchingPermissions(false);
            }
            else {
                setFetchingPermissions(false);
            }
        }

        prevProps.current = props;  // Update the ref with the current props
    }, [
        props.getKeysResult, 
        props.createKeyResult, 
        props.getKeyByIdResult, 
        props.updateKeyResult, 
        props.deleteKeyResult,
        props.getUserSystemKeyPermissionResult,
    ]);

    const getKeyName =  useMemo(() => {
        let keyName = itemId;
        if (props.keys && props.keys.Items && props.keys.Items.length > 0) {
          let key = props.keys.Items.find((key) => key && key.keyId === itemId);
          if (key) {
            keyName = key.name;
          }
        } else if (props.selectedKey && props.selectedKey.keyId === itemId) {
          keyName = props.selectedKey.name;
        }
        return keyName;
      }, [props.keys, props.selectedKey, itemId]);
    

    const showBreadCrumbNavigationItems = useCallback(() => {
        if (props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", tenant.toLowerCase())
                        .replace(":configurationItem", configurationItem.toLowerCase()),
                    text: "System Key"
                }
            ];
            if (itemAction) {
                let keyName = "";
                switch (itemAction.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({ route: "", text: "New Key" });
                        break;
                    case "view":
                    case "edit":
                        // keyName = getKeyName();
                        breadCrumbItems.push({ route: "", text: getKeyName });
                        break;
                    case "history":
                        breadCrumbItems.push({ route: "", text: getKeyName},{ route: "", text: "History" });
                        break;
                    default:
                }
            }

            props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }, [props, tenant, configurationItem, itemAction, getKeyName]);

    

    const showCreateKey = useCallback(() => {
        history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", tenant)
            .replace(":configurationItem", configurationItem.toLowerCase())
            .replace(":itemAction", "create"));
    }, [history, tenant, configurationItem]);

    const showViewKey = useCallback((keyId) => {
        history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", tenant)
            .replace(":configurationItem", configurationItem.toLowerCase())
            .replace(":itemId", keyId)
            .replace(":itemAction", "view"));
    }, [history, tenant, configurationItem]);

    const showEditKey = useCallback((keyId) => {
        history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", tenant)
            .replace(":configurationItem", configurationItem.toLowerCase())
            .replace(":itemId", keyId)
            .replace(":itemAction", "edit"));
    }, [history, tenant, configurationItem]);

    const cancelManageKey = useCallback(() => {
        history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", tenant)
            .replace(":configurationItem", configurationItem.toLowerCase()));
    }, [history, tenant, configurationItem]);
    const showHistory = useCallback((keyId) => {
        history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", tenant)
            .replace(":configurationItem", configurationItem.toLowerCase())
            .replace(":itemId", keyId)
            .replace(":itemAction", "history"));
    }, [history, tenant, configurationItem]);

    const createKey = useCallback((keyData) => {
        setBusy(true);
        setBusyMessage("Creating key...");
        props.createKey(keyData);
    }, []);

    const updateKey = useCallback((keyData, keyId) => {
        setBusy(true);
        setBusyMessage("Updating key...");
        props.updateKey(keyData, keyId);
    }, []);

    const showDeleteKeyConfirmation = useCallback((key) => {
        setDeletedKey(key);
        setShowKeyDeleteConfirmation(true);
        setDeleteKeyConfirmMessage(`Are you sure you want to delete '${key.name}' key?`);
    }, []);

    const onDeleteKeyConfirmed = useCallback(async() => {
        setBusy(true);
        setBusyMessage("Deleting key...");
        await props.deleteKey(deletedKey.keyId);
        props.getKeys();
        onDeleteKeyCancel();
    }, [deletedKey]);

    const onDeleteKeyCancel = useCallback(() => {
        setDeletedKey(null);
        setShowKeyDeleteConfirmation(false);
        setDeleteKeyConfirmMessage("");
    }, []);

    const isBusy = () => {
        // console.log("busy", busy);
        let isBusy=(isbusy||fetchingPermissions)
        // if (itemAction=== "view" || itemAction=== "edit") {
        //     isBusy = isBusy || fetchingKeys;
        // }
        // console.log("isBusy", isBusy);
        return isBusy
    }

    const onKeyValuesChanged = useCallback((changedValues, allValues) => {
        if (!_.isEmpty(manageKeyErrors)) {
            let updatedErrors = { ...manageKeyErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete updatedErrors[key];
            }
            setManageKeyErrors(updatedErrors);
        }
    }, [manageKeyErrors]);

    const getKeyComponent =()=> {
        if(permissionDenied){
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (!itemAction) {
            return (
                <>
                    <OkCancelConfirmationBox
                        show={showKeyDeleteConfirmation}
                        message={deleteKeyConfirmMessage}
                        onCancel={onDeleteKeyCancel}
                        onOk={onDeleteKeyConfirmed}
                    />
                    {!fetchingKeys ? (
                    <KeysList
                        keyList={props.keys.Items}
                        onCreateNewKey={showCreateKey}
                        onEditKey={showEditKey}
                        onDeleteKey={showDeleteKeyConfirmation}
                        onViewKey={showViewKey}
                        onShowHistory={showHistory} 
                        permission={props.permission}
                    />):<></>}
                </>
            );
        }
        switch (itemAction) {
            case "create":
                return (
                    <ManageKeys
                        action={itemAction}
                        createKey={createKey}
                        onCancel={cancelManageKey}
                        formErrors={manageKeyErrors}
                        onValuesChanged={onKeyValuesChanged}
                        permission={props.permission}
                    />
                );
            case "view":
            case "edit":
                return  (
                    props.selectedKey ?
                        <ManageKeys
                            action={itemAction}
                            keyData={props.selectedKey}
                            onCancel={cancelManageKey}
                            updateKey={updateKey}
                            formErrors={manageKeyErrors}
                            onValuesChanged={onKeyValuesChanged}
                            permission={props.permission}
                        />
                        :(!props.getKeyByIdResult.success?
                        <Message floating size='big'>
                            <Message.Header>Key not found.</Message.Header>
                            <p>The key you are looking for does not exist.</p>
                        </Message>
                        :<></>
                        )
                );
            case "history":
                return showKeyHistory? <SystemKeysAudit keyData={props.selectedKey} onClose={cancelManageKey}></SystemKeysAudit>:<></> ;
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    return (
        <div className="full-height-flex-container verticle-scroll">
            <LoadingOverlay
                busy={isBusy()}
                spinner
                message={busyMessage||"Please wait..."}
            >
            </LoadingOverlay>
            {!fetchingPermissions?getKeyComponent():<></>}
        </div>
    );
};

const mapStateToProps = (state) => ({
    getKeysResult: state.systemKeys.getKeyListResult,
    createKeyResult: state.systemKeys.createKeyResult,
    getKeyByIdResult: state.systemKeys.getKeyByIdResult,
    updateKeyResult: state.systemKeys.updateKeyResult,
    deleteKeyResult: state.systemKeys.deleteKeyResult,
    keys: state.systemKeys.keys,
    selectedKey: state.systemKeys.selectedKey,
    loading: state.systemKeys.loading,
    error: state.systemKeys.error,
    permission: state.systemKeys.permission,
    getUserSystemKeyPermissionResult: state.systemKeys.getUserSystemKeyPermissionResult
});
const mapDispatchToProps = (dispatch) => ({
    getKeys: () => dispatch(actions.getKeysRequest()),
    createKey: (keyData) => dispatch(actions.createKeyRequest(keyData)),
    getKeyById: (keyId) => dispatch(actions.getKeyByIdRequest(keyId)),
    updateKey: (keyData, keyId) => dispatch(actions.updateKeyRequest(keyData, keyId)),
    deleteKey: (keyId) => dispatch(actions.deleteKeyRequest(keyId)),
    getUserSystemKeyPermission: () => dispatch(actions.getUserSystemKeyPermissionRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemKeys);
