import { put, call, takeEvery, select, all,takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;
function* getKeys(api) {
    let response = yield call([api, api.get], `${Endpoints.integration}/api/v1/keys`);
    return response.data;
}
function* getKeyById(api, keyId) {
    let response = yield call([api, api.get], `${Endpoints.integration}/api/v1/keys/item/${keyId}`);
    return response.data;
}
function* getKeyByType(api, keyType) {
    let response = yield call([api, api.get], `${Endpoints.integration}/api/v1/keys/${keyType}`);
    return response.data;
}
function* postKey(api, keyData) {
    let response = yield call([api, api.post], `${Endpoints.integration}/api/v1/keys`, keyData);
    return response.data;
}
function* putKey(api, keyId, keyData) {
    let response = yield call([api, api.put], `${Endpoints.integration}/api/v1/keys/item/${keyId}`, keyData);
    return response.data;
}

function* deleteKeyById(api, keyId) {
    let response = yield call([api, api.delete], `${Endpoints.integration}/api/v1/keys/item/${keyId}`);
    return response.data;
}

function* getUserSystemKeyPermission(api,keyId,tenantId) {
    let result= yield call([api, api.get],`${Endpoints.permissions}/api/v1/users/${keyId}/permissions/resources/systemintegrationkeys`,{ tenantid: tenantId });
    console.log("Permissions",result.data);
    return result.data;
}
export function* fetchKeysRequest(api) {
    try {
        let keys = yield call(getKeys, api);
        yield put(actions.getKeysSuccess(keys));
    } catch (error) {
        let errorObject = errorHandler(error, 'Get', 'keys');
        yield put(actions.getKeysFailure(errorObject));
    }
}
export function* getKeyByIdRequest(api, { keyId }) {
    try {
        let key = yield call(getKeyById, api, keyId);
        yield put(actions.getKeyByIdSuccess(key));
    } catch (error) {
        let errorObject = errorHandler(error, 'Get', 'key by id');
        yield put(actions.getKeyByIdFailure(errorObject));
    }
}
export function* getKeyByTypeRequest(api, { keyType }) {
    // console.log("keyType",keyType);
    try {
        let key = yield call(getKeyByType, api, keyType);
        // console.log("key",key);
        yield put(actions.getKeyByTypeSuccess(key));
    } catch (error) {
        let errorObject = errorHandler(error, 'Get', 'key by type');
        yield put(actions.getKeyByTypeFailure(errorObject));
    }
}

export function* createKeyRequest(api,  {keyData} ) {
    try {
        let newKey = yield call(postKey, api, keyData);
        yield put(actions.createKeySuccess(newKey));
    } catch (error) {
        let errorObject = errorHandler(error, 'Create', 'key');
        yield put(actions.createKeyFailure(errorObject));
    }
}

export function* updateKeyRequest(api, { keyId, keyData }) {
    // let { keyId, keyData } = payload;
    try {
        let updatedKey = yield call(putKey, api, keyId, keyData);
        yield put(actions.updateKeySuccess(updatedKey));
    } catch (error) {
        let errorObject = errorHandler(error, 'Update', 'key');
        yield put(actions.updateKeyFailure(errorObject));
    }
}

export function* deleteKeyRequest(api, { keyId }) {
    try {
        yield call(deleteKeyById, api, keyId);
        yield put(actions.deleteKeySuccess(keyId));
    } catch (error) {
        let errorObject = errorHandler(error, 'Delete', 'key');
        yield put(actions.deleteKeyFailure(errorObject));
    }
}

export function* getUserSystemKeyPermissionRequest(api) {
    let permission={ canAdd: false, canView: false, canEdit: false, canDelete: false };
    let tenant = yield select(getTenant);
    let user = yield select(getUser);
    try {
        let permissionResponse = yield call(getUserSystemKeyPermission, api, user.userId, tenant.tenantId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes('add')) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes('view')) {
                    permission.canView = true;
                }
                if (permissionActions.includes('edit')) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes('delete')) {
                    permission.canDelete = true;
                }
            }
        }
        yield put(actions.getUserSystemKeyPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, 'Get', 'user system key permission');
        yield put(actions.getUserSystemKeyPermissionFailure(errorObject));
    }
}
export function* watchFetchKeysRequest(api,payload) {
    yield call(fetchKeysRequest, api);
}

export function* watchCreateKeyRequest(api, { payload }) {
    yield call(createKeyRequest, api, payload);
}

export function* watchUpdateKeyRequest(api, { payload }) {
    yield call(updateKeyRequest, api, payload);
}

export function* watchDeleteKeyRequest(api, { payload }) {
    yield call(deleteKeyRequest, api, payload);
}

export function* watchGetKeyByIdRequest(api, { payload }) {
    yield call(getKeyByIdRequest, api, payload);
}
export function* watchGetKeyByTypeRequest(api, { payload }) {
    yield call(getKeyByTypeRequest, api, payload);
}
export function* watchGetUserSystemKeyPermissionRequest(api,{payload}) {
    yield call(getUserSystemKeyPermissionRequest, api);
}

export default function* ({api}) {
        yield takeLatest(actions.GET_KEY_REQUEST, watchFetchKeysRequest, api);
        yield takeLatest(actions.CREATE_KEY_REQUEST, watchCreateKeyRequest, api);
        yield takeLatest(actions.UPDATE_KEY_REQUEST, watchUpdateKeyRequest, api);
        yield takeLatest(actions.DELETE_KEY_REQUEST, watchDeleteKeyRequest, api);
        yield takeLatest(actions.GET_KEYBYID_REQUEST, watchGetKeyByIdRequest, api);
        yield takeLatest(actions.GET_KEY_KEYTYPE_REQUEST, watchGetKeyByTypeRequest, api);
        yield takeEvery(actions.GET_USER_SYSTEM_KEY_PERMISSION_REQUEST, watchGetUserSystemKeyPermissionRequest, api);
}
