// Action types for fetching, creating, updating, and deleting tenancy customization
export const GET_TENANCY_CUSTOMIZATION_REQUEST = "GET_TENANCY_CUSTOMIZATION_REQUEST";
export const GET_TENANCY_CUSTOMIZATION_SUCCESS = "GET_TENANCY_CUSTOMIZATION_SUCCESS";
export const GET_TENANCY_CUSTOMIZATION_FAILURE = "GET_TENANCY_CUSTOMIZATION_FAILURE";

export const CREATE_TENANCY_CUSTOMIZATION_REQUEST = "CREATE_TENANCY_CUSTOMIZATION_REQUEST";
export const CREATE_TENANCY_CUSTOMIZATION_SUCCESS = "CREATE_TENANCY_CUSTOMIZATION_SUCCESS";
export const CREATE_TENANCY_CUSTOMIZATION_FAILURE = "CREATE_TENANCY_CUSTOMIZATION_FAILURE";

export const UPDATE_TENANCY_CUSTOMIZATION_REQUEST = "UPDATE_TENANCY_CUSTOMIZATION_REQUEST";
export const UPDATE_TENANCY_CUSTOMIZATION_SUCCESS = "UPDATE_TENANCY_CUSTOMIZATION_SUCCESS";
export const UPDATE_TENANCY_CUSTOMIZATION_FAILURE = "UPDATE_TENANCY_CUSTOMIZATION_FAILURE";

export const DELETE_TENANCY_CUSTOMIZATION_REQUEST = "DELETE_TENANCY_CUSTOMIZATION_REQUEST";
export const DELETE_TENANCY_CUSTOMIZATION_SUCCESS = "DELETE_TENANCY_CUSTOMIZATION_SUCCESS";
export const DELETE_TENANCY_CUSTOMIZATION_FAILURE = "DELETE_TENANCY_CUSTOMIZATION_FAILURE";

// Action Creators

// Fetch tenancy customization
export const getTenancyCustomizationRequest = () => ({
    type: GET_TENANCY_CUSTOMIZATION_REQUEST,
});
export const getTenancyCustomizationSuccess = (customizationData) => ({
    type: GET_TENANCY_CUSTOMIZATION_SUCCESS,
    payload: { customizationData },
});
export const getTenancyCustomizationFailure = (error) => ({
    type: GET_TENANCY_CUSTOMIZATION_FAILURE,
    payload: { error },
});

// Create new tenancy customization
export const createTenancyCustomizationRequest = (customizationData) => ({
    type: CREATE_TENANCY_CUSTOMIZATION_REQUEST,
    payload: { customizationData },
});
export const createTenancyCustomizationSuccess = (customizationData) => ({
    type: CREATE_TENANCY_CUSTOMIZATION_SUCCESS,
    payload: { customizationData },
});
export const createTenancyCustomizationFailure = (error) => ({
    type: CREATE_TENANCY_CUSTOMIZATION_FAILURE,
    payload: { error },
});

// Update existing tenancy customization
export const updateTenancyCustomizationRequest = (customizationData) => ({
    type: UPDATE_TENANCY_CUSTOMIZATION_REQUEST,
    payload: { customizationData },
});
export const updateTenancyCustomizationSuccess = (customizationData) => ({
    type: UPDATE_TENANCY_CUSTOMIZATION_SUCCESS,
    payload: { customizationData },
});
export const updateTenancyCustomizationFailure = (error) => ({
    type: UPDATE_TENANCY_CUSTOMIZATION_FAILURE,
    payload: { error },
});

// Delete tenancy customization
export const deleteTenancyCustomizationRequest = () => ({
    type: DELETE_TENANCY_CUSTOMIZATION_REQUEST,
});
export const deleteTenancyCustomizationSuccess = () => ({
    type: DELETE_TENANCY_CUSTOMIZATION_SUCCESS,
});
export const deleteTenancyCustomizationFailure = (error) => ({
    type: DELETE_TENANCY_CUSTOMIZATION_FAILURE,
    payload: { error },
});