import {
    GET_TENANCY_CUSTOMIZATION_REQUEST,
    GET_TENANCY_CUSTOMIZATION_SUCCESS,
    GET_TENANCY_CUSTOMIZATION_FAILURE,
    CREATE_TENANCY_CUSTOMIZATION_REQUEST,
    CREATE_TENANCY_CUSTOMIZATION_SUCCESS,
    CREATE_TENANCY_CUSTOMIZATION_FAILURE,
    UPDATE_TENANCY_CUSTOMIZATION_REQUEST,
    UPDATE_TENANCY_CUSTOMIZATION_SUCCESS,
    UPDATE_TENANCY_CUSTOMIZATION_FAILURE,
    DELETE_TENANCY_CUSTOMIZATION_REQUEST,
    DELETE_TENANCY_CUSTOMIZATION_SUCCESS,
    DELETE_TENANCY_CUSTOMIZATION_FAILURE,
  } from './actions';
  
  // Initial State
  const initialState = {
    getCustomizationResult: { success: true },
    customizationData: null, // Holds the actual customization data
    createCustomizationResult: { success: true },
    updateCustomizationResult: { success: true },
    deleteCustomizationResult: { success: true },
    selectedCustomization: null, // Holds data for a single customization when necessary
    loading: false,
    error: null,
  };
  
  // Reducer function
  export default (state = initialState, action) => {
    switch (action.type) {
      // Common cases for loading states
      case GET_TENANCY_CUSTOMIZATION_REQUEST:
      case CREATE_TENANCY_CUSTOMIZATION_REQUEST:
      case UPDATE_TENANCY_CUSTOMIZATION_REQUEST:
      case DELETE_TENANCY_CUSTOMIZATION_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      // Success cases for each operation
      case GET_TENANCY_CUSTOMIZATION_SUCCESS:
        return {
          ...state,
          getCustomizationResult: { success: true },
          customizationData: action.payload.customizationData,
          loading: false,
        };
  
      case CREATE_TENANCY_CUSTOMIZATION_SUCCESS:
        return {
          ...state,
          createCustomizationResult: { success: true },
          customizationData: action.payload.customizationData,
          loading: false,
        };
  
      case UPDATE_TENANCY_CUSTOMIZATION_SUCCESS:
        return {
          ...state,
          updateCustomizationResult: { success: true },
          customizationData: action.payload.customizationData,
          loading: false,
        };
  
      case DELETE_TENANCY_CUSTOMIZATION_SUCCESS:
        return {
          ...state,
          deleteCustomizationResult: { success: true },
          customizationData: null, // or update the state to reflect the deletion
          loading: false,
        };
  
      // Failure cases for each operation
      case GET_TENANCY_CUSTOMIZATION_FAILURE:
        return {
          ...state,
          getCustomizationResult: { success: false, ...action.payload.error },
          customizationData: null,
          loading: false,
        };
  
      case CREATE_TENANCY_CUSTOMIZATION_FAILURE:
        return {
          ...state,
          createCustomizationResult: { success: false, ...action.payload.error },
          loading: false,
        };
  
      case UPDATE_TENANCY_CUSTOMIZATION_FAILURE:
        return {
          ...state,
          updateCustomizationResult: { success: false, ...action.payload.error },
          loading: false,
        };
  
      case DELETE_TENANCY_CUSTOMIZATION_FAILURE:
        return {
          ...state,
          deleteCustomizationResult: { success: false, ...action.payload.error },
          loading: false,
        };
  
      // Default case: return the current state if no action matches
      default:
        return state;
    }
  };
  