// Recursively find the ID and update the value
export default function editBuilderFunction(
    fb: IFormulaBuilder,
    editedFunction: IFormulaFunction,
) {
    if (fb.formulaFunction.id === editedFunction.id) {
        // Found edited ID
        if (fb.children && editedFunction.type !== "formula") {
            // Remove children if it's been changed to Variable or String
            fb.children = undefined;
        }
        fb.formulaFunction = editedFunction;
    } else if (fb.children) {
        fb.children.forEach((child) =>
            editBuilderFunction(child, editedFunction),
        );
    }
}
