import {
    ExclamationCircleOutlined,
    FileExclamationFilled,
} from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import convertFormulaBuilderToString from "common/convertFormulaBuilderToString";
import { Parser } from "hot-formula-parser";
import { isEmpty } from "lodash";
import React from "react";
import DataMapperBuilderInput from "./DataMapperBuilderInput";

const { Option } = Select;

type DataMapperProps = {
    destinationFields: IDataMappingField[];
    sourceFields: string[];
    sourceData: IFormulaParserDataInput[];
    row?: number;
    onMappingChanged: (mappedField: IDataMappingField) => void;
};

const DataMapper = ({
    destinationFields,
    sourceFields,
    sourceData,
    row = 0, // Zero-based array
    onMappingChanged,
}: DataMapperProps) => {
    const onMappingTypeChange = React.useCallback(
        (field: IDataMappingField, value: IDataMappingType) => {
            if (field.mappingType === value) {
                // Nothing has changed
                return;
            }

            const changedField: IDataMappingField = {
                ...field,
                mappingType: value,
                value: "",
                sourceColumns: [],
                variables: [],
                builder: undefined,
            };

            onMappingChanged(changedField);
        },
        [onMappingChanged],
    );

    const onDataColumnChange = React.useCallback(
        (field: IDataMappingField, value: string) => {
            if (field.value === value) {
                // Nothing has changed
                return;
            }

            const changedField: IDataMappingField = {
                ...field,
                value: value ?? "",
                sourceColumns: value ? [value] : [],
            };

            onMappingChanged(changedField);
        },
        [onMappingChanged],
    );

    const sourceFieldOptions = React.useMemo(() => {
        const options: { label: string; value: string }[] = [];
        const sortedFields = [...sourceFields].sort();
        sortedFields
            .filter((a, i) => {
                // Remove duplicates
                if (i === 0) {
                    return true;
                }
                return a !== sortedFields[i - 1];
            })
            .map((f) =>
                options.push({
                    value: f,
                    label: f,
                }),
            );

        return options;
    }, [sourceFields]);

    const handleComputeSample = React.useCallback(
        (field: IDataMappingField): string | null => {
            if (!sourceData) {
                return "";
            }

            if (field.mappingType === "FORMULA" && field.builder) {
                const derivedFormula = convertFormulaBuilderToString(
                    field.builder,
                );
                const parser = new Parser();
                derivedFormula.variables.forEach((variable) => {
                    parser.setVariable(
                        variable.name,
                        sourceData[row][variable.value] || "Unknown Variable",
                    );
                });
                const computed = parser.parse(derivedFormula.formula);

                if (!computed.error) {
                    return `${computed.result}`;
                }
            } else if (field.mappingType === "DATA_COLUMN") {
                // In case the header is empty value
                if (!isEmpty(field.value)) {
                    return sourceData[row][field.value];
                }
            }

            return null;
        },
        [row, sourceData],
    );

    return (
        <div className="flex size-full flex-1 flex-col">
            <div className="grid grid-cols-[max-content_max-content_200px_1fr_300px] text-sm">
                <div className="border-b border-r border-gray-100 border-gray-200/50 bg-gray-50 p-2 font-bold">
                    Name
                </div>
                <div className="border-b border-r border-gray-100 border-gray-200/50 bg-gray-50 p-2 font-bold">
                    Data Type
                </div>
                <div className="border-b border-r border-gray-100 border-gray-200/50 bg-gray-50 p-2 font-bold">
                    Mapping Type
                </div>
                <div className="border-b border-r border-gray-100 border-gray-200/50 bg-gray-50 p-2 font-bold">
                    Mapped Value
                </div>
                <div className="border-b border-gray-100 bg-gray-50 p-2 font-bold">
                    Sample Value
                </div>
                {destinationFields.map((field, index) => (
                    <React.Fragment key={`${field.name}-${index}`}>
                        <div className="flex flex-row items-center justify-between gap-2 border-b border-gray-100 p-2">
                            <Tooltip placement="topLeft" title={field.name}>
                                {field.name}
                            </Tooltip>
                            {!field.value && (
                                <Tooltip
                                    placement="top"
                                    title={`${field.name} is not mapped`}
                                    className="text-red-500"
                                >
                                    <ExclamationCircleOutlined />
                                </Tooltip>
                            )}
                        </div>

                        <div className="flex flex-row items-center border-b border-gray-100 p-2">
                            <Tooltip placement="topLeft" title={field.dataType}>
                                {field.dataType}
                            </Tooltip>
                        </div>

                        <div className="flex flex-row items-center border-b border-gray-100 p-2">
                            <Select<IDataMappingType>
                                style={{ width: "100%" }}
                                defaultValue={
                                    field.mappingType ?? "DATA_COLUMN"
                                }
                                onChange={(value) =>
                                    onMappingTypeChange(field, value)
                                }
                                value={field.mappingType}
                            >
                                <Option value="DATA_COLUMN">Data column</Option>
                                <Option value="FORMULA">Formula</Option>
                            </Select>
                        </div>

                        {field.mappingType === "DATA_COLUMN" ? (
                            <div className="flex flex-row items-center border-b border-gray-100 p-2">
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch
                                    allowClear={true}
                                    placeholder="Search to Select"
                                    options={sourceFieldOptions}
                                    optionFilterProp="label"
                                    onChange={(value) =>
                                        onDataColumnChange(field, value)
                                    }
                                    defaultValue={field.value}
                                />
                            </div>
                        ) : (
                            <DataMapperBuilderInput
                                field={field}
                                sourceColumns={[...sourceFields]}
                                onMappingChanged={onMappingChanged}
                                className="flex flex-row items-center border-b border-gray-100 p-2"
                            />
                        )}
                        <div className="flex flex-row items-center border-b border-gray-100 p-2">
                            {handleComputeSample(field)}
                        </div>
                    </React.Fragment>
                ))}
            </div>
            {destinationFields.length === 0 && (
                <div className="flex w-full flex-1 flex-col p-2">
                    <div className="flex w-full flex-1 flex-col items-center justify-center overflow-auto border border-dashed border-slate-300">
                        <FileExclamationFilled className="mb-3 text-3xl text-slate-500" />
                        <p className="text-sm">No data imported</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DataMapper;
