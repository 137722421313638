import { Typography } from "antd";
import { PageLayout } from "components";
import ErrorBoundary from "components/atoms/ErrorBoundary";
import { DataObjectSearch } from "containers";

const SearchPage = () => {
    return (
        <PageLayout
            mainContent={({
                setMainColumnSpan,
                setBreadcrumbNavigationItems,
            }: IPageLayoutMainContentProps) => (
                <ErrorBoundary>
                    <DataObjectSearch
                        setMainColumnSpan={setMainColumnSpan}
                        setBreadcrumbNavigationItems={
                            setBreadcrumbNavigationItems
                        }
                    />
                </ErrorBoundary>
            )}
            helpContent={() => (
                <Typography>We're working on this documentation.</Typography>
            )}
        />
    );
};

export default SearchPage;
