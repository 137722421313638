import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Popover } from "antd";
import { SUPPORTED_FORMULAS } from "hot-formula-parser";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import "../DataPreview/style.css";
import FormulaSuggestionOptions from "./FormulaSuggestionOptions";

type FormulaSuggestionInputProps = {
    defaultValue?: string;
    sourceColumns: string[];
    onSelect: (selected: IFormulaFunction, parentId?: string) => void;
    onClose?: () => void;
};

// Ensure no repeated formulas:
// e.g. SPLIT is duplicated in original SUPPORTED_FORMULAS
const noRepeatedSupportedFormula = [...SUPPORTED_FORMULAS]
    .sort()
    .filter(function (item, pos, ary) {
        return !pos || item !== ary[pos - 1];
    });

export default function FormulaSuggestionInput({
    defaultValue,
    sourceColumns,
    onSelect,
    onClose,
}: FormulaSuggestionInputProps) {
    const [formula, setFormula] = React.useState<string>(defaultValue ?? "");
    const [openSuggestion, setOpenSuggestion] = React.useState<boolean>(false);

    const sortedSourceColumns = React.useMemo(
        () =>
            sourceColumns.sort().filter(function (item, pos, ary) {
                return !pos || item !== ary[pos - 1];
            }),
        [sourceColumns],
    );

    const handleSaveFormula = React.useCallback(
        (newFormula: string) => {
            setOpenSuggestion(false);
            const hasVariable = sourceColumns.find(
                (v) => v.toLowerCase() === newFormula.toLowerCase(),
            );
            if (hasVariable) {
                // Found variable
                onSelect({
                    id: uuidv4(),
                    label: hasVariable,
                    value: hasVariable,
                    type: "variable",
                });
                // Clear input
                setFormula("");
                return;
            }

            const hasFormula = noRepeatedSupportedFormula.find(
                (f) => f.toLowerCase() === newFormula.toLowerCase(),
            );
            if (hasFormula) {
                // Found formula
                onSelect({
                    id: uuidv4(),
                    label: hasFormula,
                    value: hasFormula,
                    type: "formula",
                });
                // Clear input
                setFormula("");
                return;
            }

            // Simply a text
            onSelect({
                id: uuidv4(),
                label: newFormula,
                value: newFormula,
                type: "string",
            });
            // Clear input
            setFormula("");
        },
        [sourceColumns, onSelect],
    );

    const handleInsertFormula = React.useCallback(
        (selected: IFormulaFunction) => {
            handleSaveFormula(selected.value);
        },
        [handleSaveFormula],
    );

    const handleConfirmInput = React.useCallback(() => {
        handleSaveFormula(formula);
    }, [formula, handleSaveFormula]);

    const variableHintOptions = React.useMemo((): IFormulaFunction[] => {
        const _hints: IFormulaFunction[] = [];
        sortedSourceColumns.forEach((sc) => {
            sc.toLowerCase().includes(formula.toLowerCase()) &&
                _hints.push({
                    label: sc,
                    value: sc,
                    type: "variable",
                });
        });

        return _hints;
    }, [sortedSourceColumns, formula]);

    const formulaHintOptions = React.useMemo((): IFormulaFunction[] => {
        // On load, populate autocomplete hint options
        const _hints: IFormulaFunction[] = [];
        noRepeatedSupportedFormula.forEach((sf) => {
            sf.toLowerCase().includes(formula.toLowerCase()) &&
                _hints.push({
                    label: sf,
                    value: sf,
                    type: "formula",
                });
        });
        return _hints;
    }, [formula]);

    const content = React.useMemo((): React.ReactNode => {
        return (
            <div className="scroll-webkit max-h-[300px] w-full overflow-auto">
                <FormulaSuggestionOptions
                    variableHintOptions={variableHintOptions}
                    formulaHintOptions={formulaHintOptions}
                    onInsertFormula={handleInsertFormula}
                />
            </div>
        );
    }, [formulaHintOptions, handleInsertFormula, variableHintOptions]);

    return (
        <Popover
            content={content}
            arrow={false}
            placement="bottomLeft"
            open={openSuggestion}
            overlayClassName="w-[90%] max-w-[500px]"
        >
            <Input
                size="middle"
                defaultValue={defaultValue}
                placeholder="Enter variable or formula"
                onChange={(e) => setFormula(e.currentTarget.value)}
                onPressEnter={(e) => handleConfirmInput()}
                onFocus={() => setOpenSuggestion(true)}
                onBlur={() => {
                    // Wait for a while before closing Suggestion panel
                    // so that any clicked event can be captured first
                    setTimeout(() => setOpenSuggestion(false), 300);
                }}
                className="nodrag"
                style={{
                    minWidth: "200px",
                }}
                suffix={
                    formula.length ? (
                        <CheckOutlined
                            className="text-green-500 hover:text-green-400"
                            onClick={handleConfirmInput}
                        />
                    ) : (
                        <CloseOutlined
                            className="text-red-500 hover:text-red-400"
                            onClick={onClose}
                        />
                    )
                }
            />
        </Popover>
    );
}
