import React from 'react';
import { Tooltip } from 'antd';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import FileIcon from 'components/atoms/FileIcon';
import ActionButton from 'components/atoms/ActionButton';

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 KB';
  
  const kb = bytes / 1024;
  const mb = kb / 1024;
  const gb = mb / 1024;

  if (gb >= 1) {
    return `${gb.toFixed(2)} GB`;
  } else if (mb >= 1) {
    return `${mb.toFixed(2)} MB`;
  } else {
    return `${Math.round(kb)} KB`;
  }
};

const FilePreview = ({ 
  file, 
  onPreview, 
  onRemove, 
  loadingPreview 
}) => (
  <div className="grid grid-cols-12 items-center gap-2 p-2 bg-white rounded shadow-sm">
    <div className="col-span-7 flex items-center space-x-3">
      <FileIcon fileType={file.type} />
      <ActionButton
        icon={EyeOutlined}
        onClick={() => onPreview(file)}
        disabled={loadingPreview}
        tooltip="Preview"
        className="text-blue-600 hover:text-blue-800 hover:bg-blue-100"
      />
      <Tooltip title={file.name}>
        <span className="truncate">{file.name}</span>
      </Tooltip>
    </div>
    <div className="col-span-3 text-right">
      <span className="text-xs text-gray-500">
        {formatFileSize(file.size)}
      </span>
    </div>
    <div className="col-span-2 flex items-center justify-end space-x-1">
      <ActionButton
        icon={DeleteOutlined}
        onClick={() => onRemove(file)}
        tooltip="Delete"
        className="text-red-600 hover:text-red-800 hover:bg-red-100"
      />
    </div>
  </div>
);

export default FilePreview;