import { DeleteFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import FormulaPresenter from "./FormulaPresenter";

type FormulaBuilderProps = {
    builder?: IFormulaBuilder;
    sourceColumns?: string[];
    onSelect: (selected: IFormulaFunction, parentId?: string) => void;
    onEdit: (edited: IFormulaFunction) => void;
    onReset: () => void;
};

export default function FormulaBuilder({
    builder,
    sourceColumns = [],
    onSelect,
    onEdit,
    onReset,
}: FormulaBuilderProps) {
    return (
        <div className="flex min-h-[32px] w-full flex-row items-center justify-between gap-1 rounded-sm">
            <div className="flex flex-row items-center overflow-hidden text-sm">
                <FormulaPresenter
                    builder={builder}
                    sourceColumns={sourceColumns}
                    onSelect={onSelect}
                    onEdit={onEdit}
                />
            </div>
            {!!builder && (
                <Tooltip title="Clear all">
                    <button
                        className="flex size-[24px] items-center justify-center rounded-[4px] bg-red-500 text-white hover:bg-red-400"
                        onClick={onReset}
                    >
                        <DeleteFilled style={{ fontSize: 12 }} />
                    </button>
                </Tooltip>
            )}
        </div>
    );
}
