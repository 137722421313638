type FormulaSuggestionOptionTitleProps = {
    title?: string;
    children?: React.ReactNode;
};

export default function FormulaSuggestionOptionTitle({
    title,
    children,
}: FormulaSuggestionOptionTitleProps) {
    return (
        <div className="sticky top-0 flex flex-row items-center justify-between bg-white py-1">
            <p className="text-sm font-bold">{title}</p>
            {children}
        </div>
    );
}
