import { Input } from "antd";
import React from "react";

interface FormColorPickerFieldProps {
    value?: string;
    testName?: string;
    onChange?: (value: string) => void;
}

const FormColorPickerField: React.FC<FormColorPickerFieldProps> = ({
    value,
    testName,
    onChange,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div className="mt-0 flex flex-row">
            <Input
                type="color"
                value={value}
                onChange={handleChange}
                className="top-1 mr-2 !h-[40px] w-[132px] border-none p-0"
            />
            <Input
                type="text"
                data-testid={testName}
                value={value}
                onChange={handleChange}
                className="top-[3px] !h-[40px] w-[160px] rounded-none border border-[#d9d9d9] pl-2 pl-3 text-center"
            />
        </div>
    );
};

export default FormColorPickerField;
