import { SUPPORTED_FORMULAS } from "hot-formula-parser";
import DefinitionsData from "./definitions.json";

// Ensure no repeated formulas:
// e.g. SPLIT is duplicated in original SUPPORTED_FORMULAS
const noRepeatedSupportedFormula = [...SUPPORTED_FORMULAS]
    .sort()
    .filter(function (item, pos, ary) {
        return !pos || item !== ary[pos - 1];
    });

export default function FormulaDictionary(): IFormulaDictionary {
    const definitions: IFormulaDictionary = new Map();
    const referenceData: IFunctionDefinition[] = DefinitionsData.data;

    for (let i = 0; i < noRepeatedSupportedFormula.length; i++) {
        const foundDefinition = referenceData.find(
            (r) =>
                r.name.toUpperCase() ===
                noRepeatedSupportedFormula[i].toUpperCase(),
        );
        if (foundDefinition) {
            definitions.set(noRepeatedSupportedFormula[i], {
                ...foundDefinition,
                name: noRepeatedSupportedFormula[i],
            });
        } else {
            definitions.set(noRepeatedSupportedFormula[i], {
                name: noRepeatedSupportedFormula[i],
                description: "Unknown",
                category: "Unknown",
            });
        }
    }

    return definitions;
}
