import React from "react";
import LoadingOverlay from "components/atoms/LoadingOverlay";
import axios from "axios";
import GoogleMap from "components/organisms/GoogleMaps";
import actions from 'store/actions';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { showError, showSuccess } from 'common/ToastNotifications';
import isEqual from 'lodash/isEqual';
class LocationContainer extends React.Component {
  state = {
    location: null,
    error: null,
    loading: true,
    apiKeyFetched: false,
  };

  componentDidMount() {
    this.props.getKeyByType('GOOGLE_MAP_KEY');
  }

  componentDidUpdate(prevProps) {
    const { selectedDataObject, markers, apiKey, error, getKeyByTypeResult } = this.props;

    if (getKeyByTypeResult !== prevProps.getKeyByTypeResult) {
      this.handleKeyFetchResult(getKeyByTypeResult);
    }

    if (error !== prevProps.error) {
      this.handleApiKeyError(error);
    }

    if (
      (selectedDataObject !== prevProps.selectedDataObject || !isEqual(markers ,prevProps.markers)) &&
      this.state.apiKeyFetched && !this.state.loading
    ) {
      this.handleDataChange(selectedDataObject, markers);
    }
  }

  handleKeyFetchResult = (result) => {
    if (result && result.success) {
      this.setState({ apiKeyFetched: true, loading: false, error: null }, () => {
        this.handleDataChange(this.props.selectedDataObject, this.props.markers);
      });
      showSuccess('Google Maps Key Found!');
    } else {
      this.setError('CONFIG_ERROR', 'No Google Maps API key found.');
    }
  }

  handleApiKeyError = (error) => {
    if (error) {
      this.setError('PERMISSION_ERROR', 'You do not have permission to access this API key.');
    }
  }

  handleDataChange = (selectedDataObject, markers) => {
    if (selectedDataObject ) {
      this.fetchLocation(selectedDataObject);
    } else if (markers && markers.length > 0) {
      this.reverseGeocode(markers[0].position.latitude, markers[0].position.longitude);
    } else {
      this.setState({ location: null, loading: false });
    }
  }

  fetchLocation = async (address) => {
    const { apiKey } = this.props;
    if (!apiKey) return;
    
    this.setState({ loading: true, error: null });
    try {
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          address: address,
          key: apiKey.key,
        },
      });
      this.handleGeocodeResponse(response);
    } catch (error) {
      this.handleRequestError(error);
    }
  };

  reverseGeocode = async (latitude, longitude) => {
    const { apiKey } = this.props;
    if (!apiKey) return;

    this.setState({ loading: true, error: null });
    try {
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          latlng: `${latitude},${longitude}`,
          key: apiKey.key,
        },
      });
      this.handleGeocodeResponse(response);
    } catch (error) {
      this.handleRequestError(error);
    }
  };

  handleGeocodeResponse = (response) => {
    const { data } = response;
    if (data.error_message) {
      this.handleGoogleMapsError(data.status, data.error_message);
    } else if (data.results && data.results.length > 0) {
      const location = data.results[0].geometry.location;
      setTimeout(() => {
        this.setState({
          location: { latitude: location.lat, longitude: location.lng },
          loading: false,
          error: null
        });
      },400); 
    } else {
      this.setError('NO_RESULTS', 'No results found for the given location.');
    }
  };

  handleRequestError = (error) => {
    if (error.response) {
      this.handleGoogleMapsError(error.response.data.status, error.response.data.error_message);
    } else if (error.request) {
      this.setError('NETWORK_ERROR', 'Network error. Please check your internet connection.');
    } else {
      this.setError('REQUEST_SETUP_ERROR', 'An error occurred while setting up the request.');
    }
  };

  handleGoogleMapsError = (status, message) => {
    switch (status) {
      case 'INVALID_REQUEST':
        this.setError('INVALID_REQUEST', 'The request was invalid.');
        break;
      case 'OVER_QUERY_LIMIT':
        this.setError('OVER_QUERY_LIMIT', 'You are over your quota.');
        break;
      case 'REQUEST_DENIED':
        this.setError('REQUEST_DENIED', 'Your request was denied.');
        break;
      case 'UNKNOWN_ERROR':
        this.setError('UNKNOWN_ERROR', 'An unknown error occurred.');
        break;
      case 'ZERO_RESULTS':
        this.setError('ZERO_RESULTS', 'No results were found for this location.');
        break;
      default:
        this.setError('GOOGLE_MAPS_ERROR', message || 'An error occurred with Google Maps.');
    }
  };

  setError = (type, message) => {
    this.setState({
      error: { type, message },
      loading: false,
      apiKeyFetched: type !== 'CONFIG_ERROR'
    });
    showError(message);
  };

  renderError = () => {
    const { error } = this.state;
    if (!error) return null;

    const errorTypes = {
      CONFIG_ERROR: 'negative',
      PERMISSION_ERROR: 'negative',
      NETWORK_ERROR: 'warning',
      INVALID_REQUEST: 'warning',
      OVER_QUERY_LIMIT: 'negative',
      REQUEST_DENIED: 'negative',
      UNKNOWN_ERROR: 'warning',
      ZERO_RESULTS: 'warning',
      NO_RESULTS: 'warning',
      REQUEST_SETUP_ERROR: 'negative',
      GOOGLE_MAPS_ERROR: 'negative'
    };

    return (
      <Message
        floating
        size='big'
        negative={errorTypes[error.type] === 'negative'}
        warning={errorTypes[error.type] === 'warning'}
      >
        <Message.Header>{error.type.replace(/_/g, ' ')}</Message.Header>
        <p>{error.message}</p>
      </Message>
    );
  }

  render() {
    const { location, loading, apiKeyFetched, error } = this.state;
    const { selectedDataObject, markers } = this.props;

    if (loading) {
      return <LoadingOverlay busy={true} spinner message="Please wait..." />;
    }

    if (error) {
      return this.renderError();
    }

    if (!apiKeyFetched) {
      return <LoadingOverlay busy={true} spinner message="Fetching API key..." />;
    }

    if (!selectedDataObject && !location ) {
      return (
        <Message warning floating size='big'>
          <Message.Header>No Location Data</Message.Header>
          <p>No location data is currently available.</p>
        </Message>
      );
    }

    if (!location) {
      return <LoadingOverlay busy={true} spinner message="Fetching location..." />;
    }

    return (
      <GoogleMap 
        keyreq={this.props.apiKey.key} 
        markers={[{ position: { latitude: location.latitude, longitude: location.longitude } }]} 
      />
    );
  }
}

const mapStateToProps = (state) => ({
  apiKey: state.systemKeys.apiKey,
  error: state.systemKeys.error,
  getKeyByTypeResult: state.systemKeys.getKeyByTypeResult,
});

const mapDispatchToProps = (dispatch) => ({
  getKeyByType: (keyType) => dispatch(actions.getKeyByTypeRequest(keyType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationContainer);