import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import Amplify, { Storage } from "aws-amplify";
import awsconfig from '../../aws-exports.js';
import { v4 as uuidv4 } from 'uuid';

import errorHandler from 'common/errorHandler';



function* getTenancyCustomization(api) {
    let response = yield call([api, api.get], `${Endpoints.tenants}/api/v1/brand`);
    return response.data;
}

function* updateTenancyCustomization(api, customizationData) {
    let response = yield call([api, api.put], `${Endpoints.tenants}/api/v1/brand`, customizationData);
    return response.data;
}

function* postTenancyCustomization(api, customizationData) {
    let response = yield call([api, api.post], `${Endpoints.tenants}/api/v1/brand`, customizationData);
    return response.data;
}

function* deleteTenancyCustomization(api) {
    let response = yield call([api, api.delete], `${Endpoints.tenants}/api/v1/brand`);
    return response.data;
}


export function* fetchTenancyCustomizationRequest(api) {
    try {
        let customizationData = yield call(getTenancyCustomization, api);
        yield put(actions.getTenancyCustomizationSuccess(customizationData));
    } catch (error) {
        if (error.response && error.response.status === 404) {
            yield put(actions.getTenancyCustomizationSuccess(null));
        } else {
            let errorObject = errorHandler(error, 'Get', 'tenancy customization');
            yield put(actions.getTenancyCustomizationFailure(errorObject));
        }
    }
}

export function* createTenancyCustomizationRequest(api, { customizationData }) {
    try {
        if (customizationData.logo) {
            Amplify.configure({
                Storage: {
                    AWSS3: {
                        bucket: 'pretectum-media',
                        region: process.env.REACT_APP_BUCKET_REGION,
                    },
                },
            });
            const file = customizationData.logo.originFileObj;
            const headers = {
                level: 'public',
                contentType: file.type,
            };

            const fileName = `tenants/customization/${uuidv4()}${file.name.slice(file.name.lastIndexOf('.'))}`;
            

            const result = yield call([Storage, Storage.put], fileName, file, headers);
            // Update customizationData to use the newly uploaded image URL
            customizationData.logo = `${process.env.REACT_APP_MEDIA_CDN_URL}public/${result.key}`; // Use the CloudFront URL
        }else {
            delete customizationData.logo;
        }
        let newCustomization = yield call(postTenancyCustomization, api, customizationData);
        yield put(actions.createTenancyCustomizationSuccess(newCustomization));
    } catch (error) {
        let errorObject = errorHandler(error, 'Create', 'tenancy customization');
        yield put(actions.createTenancyCustomizationFailure(errorObject));
    } finally {
        Amplify.configure(awsconfig);
    }
}

export function* updateTenancyCustomizationRequest(api, { customizationData }) {
    try {
        // Handle the file upload if there is an image file
        if (customizationData.logo) {
            console.log(customizationData);
            Amplify.configure({
                Storage: {
                    AWSS3: {
                        bucket: 'pretectum-media',
                        region: process.env.REACT_APP_BUCKET_REGION,
                    },
                },
            });
            const file = customizationData.logo.originFileObj;

            const headers = { level: 'public', contentType: file.type };
            const fileName = `tenants/customization/${uuidv4()}${file.name.slice(file.name.lastIndexOf('.'))}`;
            const result = yield call([Storage, Storage.put], fileName, file, headers);

            customizationData.logo = `${process.env.REACT_APP_MEDIA_CDN_URL}public/${result.key}`; // Use the CloudFront URL
        }
        const updatedCustomization = yield call(updateTenancyCustomization, api, customizationData);
        yield put(actions.updateTenancyCustomizationSuccess(updatedCustomization));
    } catch (error) {
        const errorObject = errorHandler(error, 'Update', 'tenancy customization');
        yield put(actions.updateTenancyCustomizationFailure(errorObject));
    } finally {
        Amplify.configure(awsconfig);
    }
}

export function* deleteTenancyCustomizationRequest(api) {
    try {
        yield call(deleteTenancyCustomization, api);
        yield put(actions.deleteTenancyCustomizationSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, 'Delete', 'tenancy customization');
        yield put(actions.deleteTenancyCustomizationFailure(errorObject));
    }
}

export function* watchFetchTenancyCustomizationRequest(api) {
    yield call(fetchTenancyCustomizationRequest, api);
}

export function* watchCreateTenancyCustomizationRequest(api, { payload }) {
    yield call(createTenancyCustomizationRequest, api, payload);
}

export function* watchUpdateTenancyCustomizationRequest(api, { payload }) {
    yield call(updateTenancyCustomizationRequest, api, payload);
}

export function* watchDeleteTenancyCustomizationRequest(api, { payload }) {
    yield call(deleteTenancyCustomizationRequest, api, payload);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_TENANCY_CUSTOMIZATION_REQUEST, watchFetchTenancyCustomizationRequest, api);
    yield takeLatest(actions.CREATE_TENANCY_CUSTOMIZATION_REQUEST, watchCreateTenancyCustomizationRequest, api);
    yield takeLatest(actions.UPDATE_TENANCY_CUSTOMIZATION_REQUEST, watchUpdateTenancyCustomizationRequest, api);
    yield takeLatest(actions.DELETE_TENANCY_CUSTOMIZATION_REQUEST, watchDeleteTenancyCustomizationRequest, api);
}
