import {
    ArrowRightOutlined,
    VerticalAlignBottomOutlined,
    VerticalLeftOutlined,
    VerticalRightOutlined,
} from "@ant-design/icons";
import { Alert, Button, Input, Radio, Switch, Tooltip, Typography } from "antd";
import { RcFile } from "antd/es/upload";
import mergeClassNames from "common/mergeClassNames";
import { SystemConnectionSelector } from "components";
import DockableContainer from "components/atoms/DockableContainer";
import DataMapper from "components/molecules/DataMapper";
import DataMapperFlow from "components/molecules/DataMapperFlow";
import DataPreview from "components/molecules/DataPreview";
import FileDragger from "components/molecules/FileDragger";
import { useAppDispatch } from "hooks";
import React from "react";
import { setDataMapperFlowAction } from "store/dataMapperFlow/actions";
import DataObjectBulkUploadLayout, {
    Footer,
    Header,
} from "./DataObjectBulkUploadLayout";

const { Text } = Typography;

interface IColumnDictionary {
    [key: string]: string;
}

type DataObjectBulkUploadProps = {
    bulkUploadSource: unknown;
    dataSet: IDataSet;
    schemaModel: ISchemaModel;
    onCreateDataObjectsBulkUploadJob: (
        dataUploadOptions: string,
        file: RcFile | File,
        fieldMappings: IDataMappingField[],
    ) => void;
};

interface RcFileExtension extends RcFile {
    connectionId?: string;
    connectionType?: string;
    bucket?: string;
}

export default function DataObjectBulkUpload({
    bulkUploadSource,
    dataSet,
    schemaModel,
    onCreateDataObjectsBulkUploadJob,
}: DataObjectBulkUploadProps) {
    const draggerRef = React.useRef<IFileDraggerRef>();
    const [dataPanelPosition, setDataPanelPosition] = React.useState<
        "bottom" | "left" | "right"
    >("bottom");
    const [showDataTable, setShowDataTable] = React.useState<boolean>(true);
    const [fileReadResult, setFileReadResult] =
        React.useState<IFileReadResult<RcFileExtension> | null>({
            file: null,
            data: null,
            columns: [],
            error: null,
            meta: {
                fields: [],
            },
        });
    const [dataUploadOptions, setDataUploadOptions] =
        React.useState<string>("REPLACE_DATA");

    // Passing mapping to Flow and back
    const [dataColumnMapping, setDataColumnMapping] =
        React.useState<IMappingSchemaFields>();

    // Store the source data headers
    const columnsDict = React.useRef<IColumnDictionary>();

    const [readingFile, setReadingFile] = React.useState<boolean>(false);
    const [validationErrors, setValidationErrors] = React.useState<string[]>(
        [],
    );
    const [mappedSchemaColumns, setMappedSchemaColumns] = React.useState<
        string[]
    >([]);

    const [showFlowMapper, setShowFlowMapper] = React.useState<boolean>(false);
    const [sampleDataRow, setSampleDataRow] = React.useState<number>(0);

    const onReadingFile = React.useCallback(() => {
        setReadingFile(true);
    }, []);

    const findMappedSchemaColumns = React.useCallback(() => {
        const colMapping = { ...dataColumnMapping };

        // Update the mapped schame columns
        const newColumns: string[] = [];
        Object.values({ ...colMapping })
            .filter((c) => c.sourceColumns && c.sourceColumns.length > 0)
            .forEach((field) => {
                // Construct refreshed columns
                if (field.sourceColumns && field.sourceColumns.length > 0) {
                    // Mapped multiple sources to target
                    field.sourceColumns?.forEach((col) => {
                        if (columnsDict.current && columnsDict.current[col]) {
                            // The field must exists
                            newColumns.push(col);
                        }
                    });
                }
            });
        newColumns.sort();
        if (
            JSON.stringify(mappedSchemaColumns) !== JSON.stringify(newColumns)
        ) {
            setMappedSchemaColumns(newColumns);
        }
    }, [dataColumnMapping, mappedSchemaColumns]);

    React.useEffect(() => {
        findMappedSchemaColumns();
    }, [findMappedSchemaColumns]);

    const readFileComplete = React.useCallback(
        (result: IFileReadResult<RcFileExtension>) => {
            const colMapping: IMappingSchemaFields = {};

            if (result.columns && result.columns.length > 0) {
                columnsDict.current = result.columns.reduce((dict, column) => {
                    dict[column] = column;
                    return dict;
                }, {} as IColumnDictionary);

                for (let field of schemaModel.fields) {
                    if (columnsDict.current[field.name]) {
                        colMapping[field.name] = {
                            mappingType: "DATA_COLUMN",
                            value: columnsDict.current[field.name],
                            fieldId: field.fieldId,
                            name: field.name,
                            dataType: field.dataType,
                            sourceColumns: [columnsDict.current[field.name]],
                        };
                    } else {
                        colMapping[field.name] = {
                            mappingType: "DATA_COLUMN",
                            value: "",
                            fieldId: field.fieldId,
                            name: field.name,
                            dataType: field.dataType,
                        };
                    }
                }
            }

            setFileReadResult(result);
            setDataColumnMapping(colMapping);
            setReadingFile(false);
        },
        [schemaModel.fields],
    );

    const readFileError = React.useCallback(
        (result: IFileReadResult<RcFileExtension>) => {
            setFileReadResult(result);
            setReadingFile(false);
        },
        [],
    );

    const cancelUpload = React.useCallback(() => {
        setReadingFile(false);
        setFileReadResult(null);
        setValidationErrors([]);
        setDataColumnMapping(undefined);
        if (draggerRef.current) {
            draggerRef.current.clearFile();
        }
    }, []);

    const isUploadAllowed = React.useMemo(
        () =>
            fileReadResult &&
            fileReadResult.file &&
            !fileReadResult.error &&
            !readingFile,
        [fileReadResult, readingFile],
    );

    const validateData = React.useCallback(() => {
        let validatedErrors = [];
        let validUploadColumnCount = 0;

        if (dataColumnMapping) {
            for (let field of schemaModel.fields) {
                if (
                    field.isPrimary &&
                    (!dataColumnMapping[field.name] ||
                        !dataColumnMapping[field.name].value)
                ) {
                    validatedErrors.push(
                        `No data column for primary key field '${field.name}'`,
                    );
                } else if (
                    field.isRequired &&
                    (!dataColumnMapping[field.name] ||
                        !dataColumnMapping[field.name].value)
                ) {
                    validatedErrors.push(
                        `No data column for required field '${field.name}'`,
                    );
                } else if (
                    dataColumnMapping[field.name] &&
                    dataColumnMapping[field.name].mappingType === "FORMULA" &&
                    !dataColumnMapping[field.name].value
                ) {
                    validatedErrors.push(
                        `Formula expression is empty for field '${field.name}'`,
                    );
                } else if (
                    dataColumnMapping[field.name] &&
                    dataColumnMapping[field.name].value
                ) {
                    validUploadColumnCount++;
                }
            }

            if (validUploadColumnCount === 0 && validatedErrors.length === 0) {
                validatedErrors.push(
                    "There are no data columns to upload for schema fields.",
                );
            }
        } else {
            validatedErrors.push(
                "There are no data columns to upload for schema fields.",
            );
        }

        setValidationErrors(validatedErrors);
        return validatedErrors.length === 0;
    }, [dataColumnMapping, schemaModel.fields]);

    const validateAndUpload = React.useCallback(() => {
        let isValid = validateData();
        if (isValid && fileReadResult?.file && dataColumnMapping) {
            let fieldMappings = [];
            for (let field of schemaModel.fields) {
                if (dataColumnMapping[field.name]) {
                    fieldMappings.push({
                        ...dataColumnMapping[field.name],
                        fieldId: field.fieldId,
                    });
                }
            }
            onCreateDataObjectsBulkUploadJob(
                dataUploadOptions,
                fileReadResult.file,
                fieldMappings,
            );
        }
    }, [
        dataColumnMapping,
        dataUploadOptions,
        fileReadResult,
        onCreateDataObjectsBulkUploadJob,
        schemaModel.fields,
        validateData,
    ]);

    const onValidationErrorsClose = React.useCallback(() => {
        setValidationErrors([]);
    }, []);

    const onMappingChanged = React.useCallback(
        (mappedField: IDataMappingField) => {
            const colMapping = { ...dataColumnMapping };

            // Check that it has all the new data
            colMapping[mappedField.name] = {
                ...colMapping[mappedField.name],
                ...mappedField,
            };

            // Save for upload later
            setDataColumnMapping(colMapping);

            findMappedSchemaColumns();
        },
        [dataColumnMapping, findMappedSchemaColumns],
    );

    const onConnectionSelectionComplete = React.useCallback(
        (connection: ISystemConnection | null) => {
            if (connection && connection.fileReadResult) {
                const result: IFileReadResult<RcFileExtension> = {
                    file: connection.file ?? null,
                    data: connection.fileReadResult.data,
                    columns: null,
                    meta: null,
                    error: null,
                };
                if (result.file) {
                    result.file.connectionId = connection.connectionId;
                    result.file.connectionType = connection.connectionType;
                    result.file.bucket = connection.bucket;
                }

                //Remove last empty row.
                if (result.data && result.data.length > 0) {
                    let lastRow = result.data[result.data.length - 1];
                    let lastRowValues = Object.values(lastRow);
                    if (!lastRowValues.some((value) => !value === false)) {
                        result.data.splice(result.data.length - 1, 1);
                    }
                }
                result.columns =
                    connection.fileReadResult.meta?.fields?.filter(
                        (field) => field,
                    ) ?? [];
                result.error = null;
                result.meta = connection.fileReadResult.meta;
                setFileReadResult(result);
                readFileComplete(result);
            }
        },
        [readFileComplete],
    );

    const sourceData = React.useMemo((): IFormulaParserDataInput[] => {
        if (fileReadResult?.data) {
            if (Array.isArray(fileReadResult.data[0])) {
                // type PapaparseDataNoHeader
                // Not supported at this point.
            } else {
                // type PapaparseDataWithHeader
                return fileReadResult.data as IFormulaParserDataInput[];
            }
        }
        return [];
    }, [fileReadResult]);

    // Remove the resizeObserver error due to rendering of ReactFlow
    React.useEffect(() => {
        const errorHandler = (e: any) => {
            if (
                e.message.includes(
                    "ResizeObserver loop completed with undelivered notifications",
                ) ||
                e.message.includes("ResizeObserver loop limit exceeded")
            ) {
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay",
                );
                if (resizeObserverErr) {
                    resizeObserverErr.style.display = "none";
                }
            }
        };
        window.addEventListener("error", errorHandler);

        return () => {
            window.removeEventListener("error", errorHandler);
        };
    }, []);

    const mappedDataColumns = React.useMemo((): string[] => {
        if (dataColumnMapping) {
            return Object.keys(dataColumnMapping).reduce((columns, key) => {
                if (
                    // Mapped to DATA_COLUMN
                    dataColumnMapping[key].mappingType === "DATA_COLUMN" &&
                    dataColumnMapping[key].value
                ) {
                    columns.push(dataColumnMapping[key].value);
                } else {
                    dataColumnMapping[key].variables?.forEach((v) => {
                        columns.push(v.value);
                    });
                }
                return columns;
            }, [] as string[]);
        } else {
            return [];
        }
    }, [dataColumnMapping]);

    const handleSampleRowChange = React.useCallback(
        (newRow: string) => {
            if (
                newRow &&
                parseInt(newRow) > 0 &&
                parseInt(newRow) < sourceData.length + 1
            ) {
                setSampleDataRow(parseInt(newRow) - 1);
            }
        },
        [sourceData.length],
    );

    const dispatch = useAppDispatch();
    React.useEffect(() => {
        // Set the source Data and row for DataMapper and DataMapperFlow
        dispatch(
            setDataMapperFlowAction({ data: sourceData, row: sampleDataRow }),
        );
    }, [dispatch, sourceData, sampleDataRow, showFlowMapper]);

    return (
        <DataObjectBulkUploadLayout>
            <Header>
                <>
                    <div className="flex flex-auto flex-col rounded-md bg-gray-100 p-2 px-4">
                        <p className="text-sm font-bold">
                            <span className="mr-1 font-bold text-rose-800">
                                Schema:
                            </span>
                            {schemaModel.name}
                        </p>
                        <p className="text-sm">{schemaModel.description}</p>
                    </div>
                    <div className="flex flex-auto flex-col rounded-md bg-gray-100 p-2 px-4">
                        <p className="text-sm font-bold">
                            <span className="mr-1 font-bold text-sky-800">
                                Dataset:
                            </span>
                            {dataSet.dataSetName}
                        </p>
                        <p className="text-sm">{dataSet.dataSetDescription}</p>
                    </div>
                </>
            </Header>
            {fileReadResult && dataColumnMapping && (
                <div className="flex flex-col items-start justify-start gap-3 md:flex-row md:items-center md:justify-between">
                    <div className="inline-flex gap-2">
                        <div className="inline-flex flex-nowrap items-center rounded-md bg-slate-100">
                            <div className="inline-flex flex-row gap-2 px-3">
                                <p>Data Preview</p>
                                <Tooltip
                                    title={`Toggle to ${showDataTable ? "hide" : "show"} Data Preview`}
                                >
                                    <Switch
                                        value={showDataTable}
                                        checkedChildren="ON"
                                        unCheckedChildren="OFF"
                                        onChange={(checked) =>
                                            setShowDataTable(checked)
                                        }
                                    />
                                </Tooltip>
                            </div>
                            <Tooltip title="Dock Data Preview to the left">
                                <Button
                                    type="text"
                                    title="Dock to left"
                                    onClick={() => setDataPanelPosition("left")}
                                    className={mergeClassNames(
                                        "rounded-none border-l border-r border-l-slate-200 border-r-slate-200",
                                        dataPanelPosition === "left"
                                            ? "bg-slate-200"
                                            : "",
                                    )}
                                >
                                    <VerticalRightOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Dock Data Preview to the bottom">
                                <Button
                                    type="text"
                                    title="Dock to bottom"
                                    onClick={() =>
                                        setDataPanelPosition("bottom")
                                    }
                                    className={mergeClassNames(
                                        "rounded-none border-r border-r-slate-200",
                                        dataPanelPosition === "bottom"
                                            ? "bg-slate-200"
                                            : "",
                                    )}
                                >
                                    <VerticalAlignBottomOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Dock Data Preview to the right">
                                <Button
                                    type="text"
                                    title="Dock to right"
                                    onClick={() =>
                                        setDataPanelPosition("right")
                                    }
                                    className={mergeClassNames(
                                        "rounded-l-none",
                                        dataPanelPosition === "right"
                                            ? "bg-slate-200"
                                            : "",
                                    )}
                                >
                                    <VerticalLeftOutlined />
                                </Button>
                            </Tooltip>
                        </div>

                        {!!fileReadResult?.data &&
                            fileReadResult.data.length > 0 && (
                                <div className="inline-flex items-center gap-2 rounded-md bg-slate-100 px-2">
                                    <div className="whitespace-nowrap text-sm font-bold">
                                        Sample Data Row
                                    </div>
                                    <Tooltip
                                        title={`Enter number between 1 to ${fileReadResult.data?.length}`}
                                    >
                                        <Input
                                            type="number"
                                            size="small"
                                            defaultValue={1}
                                            onChange={(e) =>
                                                handleSampleRowChange(
                                                    e.currentTarget.value,
                                                )
                                            }
                                            min={1}
                                            max={fileReadResult.data.length}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <p>Try out the new Flow Mapper</p>
                        <ArrowRightOutlined className="animate-slide" />
                        <Switch
                            value={showFlowMapper}
                            checkedChildren="Flow Mapper"
                            unCheckedChildren="Table Mapper"
                            onChange={(checked) => setShowFlowMapper(checked)}
                        />
                    </div>
                </div>
            )}
            <DockableContainer
                showPanel={
                    !!fileReadResult && !!dataColumnMapping && showDataTable
                }
                panelPosition={dataPanelPosition}
                panel={
                    fileReadResult &&
                    dataColumnMapping && (
                        <DataPreview
                            title={
                                <>
                                    <Text>{`${fileReadResult?.file?.name ?? "No file name"} - `}</Text>
                                    <Text type="secondary">
                                        {`Data Preview (${fileReadResult?.data?.length} Records)`}
                                    </Text>
                                </>
                            }
                            transposed={dataPanelPosition !== "bottom"}
                            data={
                                fileReadResult.data as Record<
                                    string,
                                    React.ReactNode
                                >[]
                            }
                            columns={fileReadResult.columns ?? []}
                            dataSchemaColumns={mappedDataColumns}
                        />
                    )
                }
            >
                <>
                    {!!fileReadResult &&
                    !!dataColumnMapping &&
                    Object.values(dataColumnMapping).length > 0 ? (
                        <div className="scroll-webkit w-full flex-auto overflow-auto border border-slate-100">
                            {showFlowMapper ? (
                                <DataMapperFlow
                                    schemaFields={[
                                        ...Object.values(dataColumnMapping),
                                    ]}
                                    sourceFields={fileReadResult.columns ?? []}
                                    onMappingChanged={onMappingChanged}
                                    fullsize={!showDataTable}
                                />
                            ) : (
                                <DataMapper
                                    destinationFields={[
                                        ...Object.values(dataColumnMapping),
                                    ]}
                                    sourceFields={fileReadResult.columns ?? []}
                                    sourceData={sourceData}
                                    onMappingChanged={onMappingChanged}
                                    row={sampleDataRow}
                                />
                            )}
                        </div>
                    ) : (
                        <div className="flex w-full flex-auto flex-col overflow-auto">
                            {validationErrors.length > 0 && (
                                <Alert
                                    message="Validation Error"
                                    description={
                                        <>
                                            {validationErrors.map(
                                                (error, index) => (
                                                    <p key={index}>{error}</p>
                                                ),
                                            )}
                                        </>
                                    }
                                    type="error"
                                    closable
                                    onClose={onValidationErrorsClose}
                                />
                            )}
                            {bulkUploadSource === "systemconnection" ? (
                                <>
                                    {fileReadResult?.data &&
                                    dataColumnMapping ? (
                                        <DataPreview
                                            title={
                                                <>
                                                    <Text>{`${fileReadResult?.file?.name ?? "No file name"} - `}</Text>
                                                    <Text type="secondary">
                                                        {`Data Preview (${fileReadResult?.data?.length} Records)`}
                                                    </Text>
                                                </>
                                            }
                                            data={
                                                fileReadResult.data as Record<
                                                    string,
                                                    React.ReactNode
                                                >[]
                                            }
                                            columns={
                                                fileReadResult.columns ?? []
                                            }
                                            dataSchemaColumns={Object.keys(
                                                dataColumnMapping,
                                            ).reduce((columns, key) => {
                                                if (
                                                    dataColumnMapping[key]
                                                        .mappingType ===
                                                        "DATA_COLUMN" &&
                                                    dataColumnMapping[key].value
                                                ) {
                                                    columns.push(
                                                        dataColumnMapping[key]
                                                            .value,
                                                    );
                                                }
                                                return columns;
                                            }, [] as string[])}
                                            onClose={cancelUpload}
                                            closeLabel="Cancel"
                                            closeTooltip="Cancel and upload another file"
                                        />
                                    ) : (
                                        <SystemConnectionSelector
                                            businessAreaId={
                                                schemaModel.businessAreaId
                                            }
                                            onConnectionSelectionComplete={
                                                onConnectionSelectionComplete
                                            }
                                        ></SystemConnectionSelector>
                                    )}
                                </>
                            ) : (
                                <FileDragger
                                    ref={draggerRef}
                                    onReadingFile={onReadingFile}
                                    onReadFileComplete={readFileComplete}
                                    onReadFileError={readFileError}
                                    onCancelUpload={cancelUpload}
                                    dataSchemaColumns={mappedSchemaColumns}
                                    showPreview={false}
                                ></FileDragger>
                            )}
                        </div>
                    )}
                </>
            </DockableContainer>
            <Footer>
                <div className="flex flex-1 flex-col gap-4 rounded-md bg-gray-100 p-2 md:flex-row md:items-center md:justify-end">
                    <Radio.Group
                        defaultValue={dataUploadOptions}
                        value={dataUploadOptions}
                        onChange={(e) => setDataUploadOptions(e.target.value)}
                    >
                        <div className="flex flex-col gap-2 md:flex-row">
                            <Radio value="REPLACE_DATA">
                                Replace existing data in the dataset
                            </Radio>
                            <Radio value="APPEND_DATA">
                                Append this data to the existing
                            </Radio>
                        </div>
                    </Radio.Group>
                    {isUploadAllowed && (
                        <Button type="default" onClick={cancelUpload}>
                            Cancel
                        </Button>
                    )}
                    <Button
                        type="primary"
                        disabled={!isUploadAllowed}
                        onClick={validateAndUpload}
                    >
                        Verify and Upload
                    </Button>
                </div>
            </Footer>
        </DataObjectBulkUploadLayout>
    );
}
