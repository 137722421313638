import convertFormulaBuilderToString from "common/convertFormulaBuilderToString";
import React from "react";
import editBuilderFunction from "../DataMapperFlow/utils/editBuilderFunction";
import insertBuilderFunction from "../DataMapperFlow/utils/insertBuilderFunction";
import FormulaBuilder from "../FormulaBuilder";

type DataMapperBuilderInputProps = {
    field: IDataMappingField;
    sourceColumns: string[];
    className?: string;
    onMappingChanged: (mappedField: IDataMappingField) => void;
};

export default function DataMapperBuilderInput({
    field,
    sourceColumns,
    className = "",
    onMappingChanged,
}: DataMapperBuilderInputProps) {
    const [builder, setBuilder] = React.useState<IFormulaBuilder | undefined>(
        field.builder,
    );

    const handleBuilder = React.useCallback(
        (
            field: IDataMappingField,
            newFunction: IFormulaFunction,
            parentId?: string,
        ) => {
            if (!builder) {
                const newBuilder: IFormulaBuilder = {
                    formulaFunction: newFunction,
                };
                setBuilder(newBuilder);

                const derivedFormula =
                    convertFormulaBuilderToString(newBuilder);

                const dataColumns = [...derivedFormula.variables].reduce(
                    (a: string[], b: IFormulaVariable) => {
                        a.push(b.value);
                        return a;
                    },
                    [],
                );

                onMappingChanged({
                    ...field,
                    mappingType: "FORMULA",
                    value: derivedFormula.formula,
                    builder: newBuilder,
                    variables: derivedFormula.variables,
                    sourceColumns: dataColumns,
                });
            } else {
                const changedBuilder: IFormulaBuilder =
                    structuredClone(builder);
                if (parentId) {
                    insertBuilderFunction(
                        changedBuilder,
                        parentId,
                        newFunction,
                    );
                }
                setBuilder(changedBuilder);

                const derivedFormula =
                    convertFormulaBuilderToString(changedBuilder);

                const dataColumns = [...derivedFormula.variables].reduce(
                    (a: string[], b: IFormulaVariable) => {
                        a.push(b.value);
                        return a;
                    },
                    [],
                );

                onMappingChanged({
                    ...field,
                    mappingType: "FORMULA",
                    value: derivedFormula.formula,
                    builder: changedBuilder,
                    variables: derivedFormula.variables,
                    sourceColumns: dataColumns,
                });
            }
        },
        [builder, onMappingChanged],
    );

    const handleEditBuilder = React.useCallback(
        (field: IDataMappingField, edited: IFormulaFunction) => {
            if (builder && edited.id) {
                // Loop through to find the ID
                const changedBuilder: IFormulaBuilder =
                    structuredClone(builder);
                editBuilderFunction(changedBuilder, edited);
                setBuilder(changedBuilder);

                const derivedFormula =
                    convertFormulaBuilderToString(changedBuilder);

                const dataColumns = [...derivedFormula.variables].reduce(
                    (a: string[], b: IFormulaVariable) => {
                        a.push(b.value);
                        return a;
                    },
                    [],
                );

                onMappingChanged({
                    ...field,
                    mappingType: "FORMULA",
                    value: derivedFormula.formula,
                    builder: changedBuilder,
                    variables: derivedFormula.variables,
                    sourceColumns: dataColumns,
                });
            }
        },
        [builder, onMappingChanged],
    );

    const handleResetBuilder = React.useCallback(() => {
        setBuilder(undefined);
        onMappingChanged({
            ...field,
            value: "",
            sourceColumns: [],
            variables: [],
            builder: undefined,
        });
    }, [field, onMappingChanged]);

    return (
        <div className={className}>
            <FormulaBuilder
                sourceColumns={sourceColumns}
                builder={builder}
                onSelect={(formulaFunc, parentId) =>
                    handleBuilder(field, formulaFunc, parentId)
                }
                onReset={handleResetBuilder}
                onEdit={(edited) => handleEditBuilder(field, edited)}
            />
        </div>
    );
}
