import React, { useState, useEffect,useMemo } from 'react';
import { Table, Button, Tooltip, Row, Col, Input, Select, Switch,Tag, message,Badge, Dropdown, Menu } from 'antd';
import { PlusOutlined, SearchOutlined,PushpinOutlined, EyeOutlined,UnorderedListOutlined, AppstoreOutlined, CalendarTwoTone, UserOutlined ,CheckOutlined,FilterOutlined, SortAscendingOutlined,StarFilled,ExclamationCircleOutlined, ExclamationCircleTwoTone, WarningOutlined, DeleteOutlined} from '@ant-design/icons';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ProhibitedArea,FullHeightContainerLayout } from 'components';
import { calcNewSortOrder } from 'common/Utility';
import _ from 'lodash';

const { Column } = Table;

const DataTagViewer = ({
  dataTagList,
  onCreateNewDataTag,
  onDeleteDataTag,
  onViewDataTag,
  onEditDataTag,
  onViewDataTagHistory,
  onMultiDeleteDataTags,
  permission,
  onTagReorder,
  onViewChange,
  viewState
}) => {
  const [tags, setTags] = useState(dataTagList);
  const [filteredTags, setFilteredTags] = useState(dataTagList);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBusinessArea, setFilterBusinessArea] = useState('');
  const[filters, setFilters] = useState({
    businessArea: '',
    updatedBy: '',
    lastUpdated: ''
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascend' });
  const [pinnedTags, setPinnedTags] = useState([]);
  const [showPinnedOnly, setShowPinnedOnly] = useState(false);
  const businessAreas = useMemo(() => Array.from(new Set(tags.map(tag => tag.businessAreaName))), [tags]);
  const updatedByUsers = useMemo(() => Array.from(new Set(tags.map(tag => tag.updatedByEmail))), [tags]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setTags(dataTagList);
  }, [dataTagList]);
  useEffect(() => {
    let result = [...tags];
    businessAreas.forEach(area => getBusinessAreaColors(area));
    if (searchTerm) {
      const normalizedSearchTerm = searchTerm.toLowerCase();
        result = result.filter(tag => {
            const searchableString = [
                tag.tagName.toLowerCase(),
                tag.tagDescription.toLowerCase(),
                tag.businessAreaName.toLowerCase(),
                tag.createdByName?.toLowerCase(),
                tag.updatedByName?.toLowerCase(),
                tag.createdByEmail?.toLowerCase(),
                tag.updatedByEmail?.toLowerCase(),
                tag.createdDate && moment(tag.createdDate).format('YYYY-MM-DD').toLowerCase(),
                tag.updatedDate && moment(tag.updatedDate).format('YYYY-MM-DD').toLowerCase(),
            ]
                .filter(Boolean) 
                .join(' ');

            return searchableString.includes(normalizedSearchTerm);
          })
    }
    if (filters.businessArea) {
      result = result.filter(tag => tag.businessAreaName === filters.businessArea);
    }
    if (filters.updatedBy) {
      result = result.filter(tag => tag.updatedByEmail === filters.updatedBy);
    }
    if (filters.lastUpdated) {
      const today = moment().startOf('day');
      result = result.filter(tag => {
        const updateDate = moment(tag.updatedDate);
        switch (filters.lastUpdated) {
          case 'Today':
            return updateDate.isSame(today, 'day');
          case 'This-Week':
            return updateDate.isSame(today, 'week');
          case 'This-Month':
            return updateDate.isSame(today, 'month');
          default:
            return true;
        }
      });
    }
    if (showPinnedOnly) {
      result = result.filter(tag => pinnedTags.includes(tag.tagId));
    }
    result.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascend' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascend' ? 1 : -1;
      }
      return 0;
    });
    setFilteredTags(result);
  }, [tags, searchTerm, filters,sortConfig, pinnedTags,  showPinnedOnly ,businessAreas]);
  const getBusinessAreaColors=(()=>{
    const colorSchemes = [
      { bg: 'bg-red-100', text: 'text-red-800', border: 'border-red-500' },
      { bg: 'bg-indigo-200', text: 'text-indigo-800', border: 'border-indigo-500' },
      { bg: 'bg-purple-100', text: 'text-purple-800', border: 'border-purple-500' },
      { bg: 'bg-pink-100', text: 'text-pink-800', border: 'border-pink-500' },
      { bg: 'bg-teal-100', text: 'text-teal-800', border: 'border-teal-500' },
      { bg: 'bg-green-100', text: 'text-green-800', border: 'border-green-500' },
      { bg: 'bg-yellow-100', text: 'text-yellow-800', border: 'border-yellow-500' },
    ];
    const businessAreaColors =new Map();
    return (businessArea)=>{
      if(businessAreaColors.has(businessArea)){
        return businessAreaColors.get(businessArea);
      }
      const colorScheme = colorSchemes[businessAreaColors.size % colorSchemes.length];
      businessAreaColors.set(businessArea, colorScheme);
      return colorScheme;
    }
  })()
  const handlePinTag = (tagId) => {
    const updatedPinnedTags = pinnedTags.includes(tagId)
      ? pinnedTags.filter(id => id !== tagId)
      : [...pinnedTags, tagId];
    setPinnedTags(updatedPinnedTags);
    localStorage.setItem('pinnedTags', JSON.stringify(updatedPinnedTags));
  };
  useEffect(() => {
    const storedPinnedTags = JSON.parse(localStorage.getItem('pinnedTags') || '[]');
    setPinnedTags(storedPinnedTags);
  }, []);

  if (!permission) {
    return <ProhibitedArea />;
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      message.error('Invalid drop location');
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    // console.log("Source index: " + sourceIndex, "Destination index: " + destinationIndex);
    if (sourceIndex === destinationIndex) {
      message.info('No changes made to the order');
      return;
    }
    const items = Array.from(tags);
    const newSortOrder=calcNewSortOrder(items,sourceIndex,destinationIndex);
    const [reorderedItem] = items.splice(sourceIndex, 1);
    const oldSortOrder = reorderedItem.sortOrder;
    reorderedItem.sortOrder = newSortOrder;
    items.splice(destinationIndex, 0, reorderedItem);
    setTags(items);
    const datatag = {
      tagId: reorderedItem.tagId,
      oldSortOrder,
      sortOrder:newSortOrder,
    };
    onTagReorder(datatag);
    message.loading('Reordering tags...'); 
  };
  // const businessAreas = Array.from(new Set(tags.map(tag => tag.businessAreaName)));
  const renderCard = (dataTag, provided) => {
    const { bg, text, border } = getBusinessAreaColors(dataTag.businessAreaName);
    const isUnassigned = dataTag.businessAreaName === 'Unassigned';
    return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className={`bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out border flex flex-col relative overflow-hidden group  ${isUnassigned ? "border-dashed border border-rose-700 bg-rose-50" : "border-gray-200 bg-white"} `}
    >
      <div className="flex items-center justify-between bg-blue-100 p-3 rounded-t-lg">
        <div className="flex flex-row items-center">
          <h3 className="text-lg font-bold text-blue-600">
            {dataTag.tagName}
          </h3>
          <span className={`text-xs font-medium px-2 py-1 mt-1 mx-2 rounded-full  ${isUnassigned ? "bg-gray-200 text-gray-600 border border-gray-400 italic" : `${bg} ${text} border ${border}`}`}>
            {dataTag.businessAreaName.toLowerCase()}
          </span>
          {isUnassigned && (
            <Tooltip title="This tag must be assigned to a business area before use">
              <WarningOutlined className="text-red-500 text-2xl"/>
            </Tooltip>
          )}
        </div>
        <div className="flex space-x-1">
          <Tooltip title={pinnedTags.includes(dataTag.tagId) ? "Remove from Favorites" : "Mark as Favorite"}>
            <StarFilled 
              className={`${pinnedTags.includes(dataTag.tagId) ? 'text-yellow-400' : 'text-gray-400 font-extrabold'} hover:text-yellow-500 cursor-pointer text-xl `}
              onClick={() => handlePinTag(dataTag.tagId)}
            />
          </Tooltip>
        </div>
      </div>
      <div className="p-4 flex-grow overflow-hidden hover:bg-slate-100">
        <Tooltip title={dataTag.tagDescription} placement="bottomLeft" overlayClassName="max-w-md">
        <div className="text-gray-800 mb-4 line-clamp-3 hover:text-clip text-lg font-light" >{dataTag.tagDescription}</div></Tooltip>
        <div className="flex flex-col items-start rounded-md text-base  text-gray-500">
  <div className="flex items-center gap-2 text-base font-medium">
    <CalendarTwoTone className="text-blue-500 text-2xl" />
    <span>{moment.utc(dataTag.updatedDate).toDate().toLocaleString()}</span>
  </div>
  <div className="flex items-center gap-2 text-base font-semibold">
    <UserOutlined className="text-green-500 text-2xl" />
    <span>{dataTag.updatedByEmail}</span>
  </div>
</div>
      </div>
      <div className="border-t border-gray-200 p-4 flex justify-between items-center ">
        <div className="flex space-x-2 ">
          <Tooltip title='Edit Data Classifier Tags'>
            <Button
              icon={<Icon name='pencil' color='blue' />}
              disabled={!permission.canEdit}
              onClick={() => onEditDataTag(dataTag.tagId)}
            />
          </Tooltip>
          <Tooltip title='View Data Classifier Tags'>
            <Button
              icon={<Icon name='eye' color='blue' />}
              disabled={!permission.canView}
              onClick={() => onViewDataTag(dataTag.tagId)}
            />
          </Tooltip>
          <Tooltip title='View Data Classifier History'>
            <Button
              icon={<Icon name='history' color='blue' />}
              disabled={!permission.canView}
              onClick={() => onViewDataTagHistory(dataTag.tagId)}
            />
          </Tooltip>
          <Tooltip title='Delete Data Classifier Tags'>
            <Button
              icon={<Icon name='trash alternate' color='blue' />}
              disabled={!permission.canDelete}
              onClick={() => onDeleteDataTag(dataTag)}
            />
          </Tooltip>
        </div>
      </div>
    </div>)
  };
  const filterMenu = (
    <Menu selectedKeys={[filters.businessArea, filters.updatedBy, filters.lastUpdated]}>
      <Menu.SubMenu key="businessArea" title="Business Area">
        {businessAreas.map(area => (
          <Menu.Item key={area} onClick={() => setFilters(prev => ({...prev, businessArea: prev.businessArea === area ? '' : area}))}>
          {area} {filters.businessArea === area && <CheckOutlined />}
        </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu key="updatedBy" title="Updated By">
        {updatedByUsers.map(user => (
        <Menu.Item key={user} onClick={() => setFilters(prev => ({...prev, updatedBy: prev.updatedBy === user ? '' : user}))}>
          {user} {filters.updatedBy === user && <CheckOutlined />}
        </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu key="lastUpdated" title="Last Updated">
      {['Today', 'This-Week', 'This-Month'].map(period => (
          <Menu.Item key={period} onClick={() => setFilters(prev => ({...prev, lastUpdated: prev.lastUpdated === period ? '' : period}))}>
            {period} {filters.lastUpdated === period && <CheckOutlined />}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    </Menu>
  );

  const sortMenu = (
    <Menu selectedKeys={[sortConfig.tagName, sortConfig.businessAreaName, sortConfig.updatedDate]}>
      <Menu.Item  key="tagName" onClick={() => setSortConfig({ key: 'tagName', direction: sortConfig.direction === 'ascend' ? 'descend' : 'ascend' })}>
        Tag Name {sortConfig.key === 'tagName' && (sortConfig.direction === 'ascend' ? '↑' : '↓')}
      </Menu.Item>
      <Menu.Item key="businessAreaName" onClick={() => setSortConfig({ key: 'businessAreaName', direction: sortConfig.direction === 'ascend' ? 'descend' : 'ascend' })}>
        Business Area {sortConfig.key === 'businessAreaName' && (sortConfig.direction === 'ascend' ? '↑' : '↓')}
      </Menu.Item>
      <Menu.Item key="updatedDate" onClick={() => setSortConfig({ key: 'updatedDate', direction: sortConfig.direction === 'ascend' ? 'descend' : 'ascend' })}>
        Last Updated {sortConfig.key === 'updatedDate' && (sortConfig.direction === 'ascend' ? '↑' : '↓')}
      </Menu.Item>
    </Menu>
  );
  const renderActiveTags = () => {
    const tags = [];
    if (filters.businessArea) tags.push({ key: 'businessArea', value: filters.businessArea });
    if (filters.updatedBy) tags.push({ key: 'updatedBy', value: filters.updatedBy });
    if (filters.lastUpdated) {
      const lastUpdatedText = {
        'Today': 'Today',
        'This-Week': 'This Week',
        'This-Month': 'This Month'
      }[filters.lastUpdated];
      tags.push({ key: 'lastUpdated', value: `Last Updated: ${lastUpdatedText}` });
    }
    if (showPinnedOnly) tags.push({ key: 'pinnedOnly', value: 'Favorites' });

    return tags.map(tag => (
      <Tag
        key={tag.key}
        closable
        onClose={() => {
          if (tag.key === 'pinnedOnly') setShowPinnedOnly(false);
          else setFilters(prev => ({ ...prev, [tag.key]: '' }));
        }}
        className="bg-teal-100 text-teal-700 border-teal-500 border-[1.5px] px-3 py-1 mr-2 mb-2 font-bold rounded-2xl shadow-sm hover:shadow-md transition-all duration-200 ease-in-out"
      >
        {tag.value}
      </Tag>
    ));
  };
  const clearSorting = () => {
    setSortConfig({ key: null, direction: 'ascend' });
  };
  const activeSortingTag = sortConfig.key && (
    <Tag
      closable
      onClose={clearSorting}
      className="bg-amber-100 text-amber-700 border-amber-500 border-[1.5px] px-3 py-1 mr-2 mb-2 font-bold rounded-2xl shadow-sm hover:shadow-md transition-all duration-200 ease-in-out"
      >
      {`Sorted by ${sortConfig.key.replace(/([A-Z])/g, ' $1')} (${sortConfig.direction})`}
    </Tag>
  );
  const isFilterActive = () => Object.values(filters).some(Boolean) || showPinnedOnly;
  const isSortActive = () => !_.isEmpty(sortConfig);
  return (
  <div className="h-5/6 p-1">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-2 md:space-y-0">
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-5 w-full md:w-auto">
          <Input
            placeholder="Search tags"
            prefix={<SearchOutlined />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full md:w-64"
          />
          <Dropdown autoFocus overlay={filterMenu} trigger={['click']}>
            <Button  icon={<FilterOutlined />}
            className={isFilterActive() ? 'bg-blue-100 text-blue-600 border-blue-300 font-semibold' : 'font-medium'}>
              Filter {isFilterActive() && <Badge  color='geekblue' count={Object.values(filters).filter(Boolean).length +  (showPinnedOnly ? 1 : 0)} />}
            </Button>
          </Dropdown>
          {viewState ==='card'?(  <Dropdown overlay={sortMenu} trigger={['click']}>
            <Button icon={<SortAscendingOutlined />}
            className={isSortActive() ? 'bg-blue-100 text-blue-600 border-blue-300' : ''}>
              Sort 
            </Button>
          </Dropdown>):<></>}
          {pinnedTags.length > 0 && (
            <Button
              icon={<StarFilled className='text-yellow-400' />}
              onClick={() => setShowPinnedOnly(!showPinnedOnly)}
              className={showPinnedOnly ? 'bg-blue-100 text-blue-600 border-blue-300' : ''}
            >
              Favorites {showPinnedOnly && <Badge color='geekblue' count={pinnedTags.length} />}
            </Button>
          )}
        </div>
        <div className="flex items-center space-x-5">
          {selectedRowKeys.length > 0 && (
          <Button
          danger
          icon={<DeleteOutlined className='text-xl' />}
          onClick={()=>{
            onMultiDeleteDataTags(selectedRowKeys);
            setSelectedRowKeys([]);
          }}
          className="rounded-lg transform scale-100 hover:scale-102 hover:bg-red-100 transition-transform duration-300 ease-out shadow-md hover:shadow-red-400"
          >
          <span className=" font-medium transition-colors duration-300">
            {selectedRowKeys.length === filteredTags.length
              ? "Delete All"
              : `Delete ${selectedRowKeys.length} tags`}
          </span>
          </Button>
          )}
          { viewState ==='card'?(
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={onCreateNewDataTag}
            disabled={!permission.canAdd}
          >
            Add Tag
          </Button>):<></>
          }
          <div className="flex shadow-sm">
            <Button
              icon={<UnorderedListOutlined />}
              onClick={() => onViewChange('list')}
              className={`rounded-l-2xl rounded-r-none hover:bg-blue-200 ${viewState !=='card' ?  'bg-blue-100 text-blue-600  ' : ''}`}
            >
              List
            </Button>
            <Button
              icon={<AppstoreOutlined />}
              onClick={() => onViewChange('card')}
              className={`rounded-r-2xl rounded-l-none ${viewState ==='card' ? 'bg-blue-100 text-blue-600' : ''}`}
            >
              Card
            </Button>
          </div>
        </div>
      </div>
      <div className="mb-4">
        {tags.length>0?renderActiveTags():<></>}
        {tags.length>0?activeSortingTag:<></>}
      </div>
      {viewState ==='card' ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="tags">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredTags.map((dataTag, index) => (
                  <Draggable key={dataTag.tagId} draggableId={dataTag.tagId} index={index}>
                    {(provided) => renderCard(dataTag, provided)}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <>
  <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
          <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <FullHeightContainerLayout
                  showHeader={false}
                  showFooter={true}
                  content={<Table
        size="small"
        rowKey="tagId"
        dataSource={filteredTags}
        pagination={false}
        scroll={{ y: "100vh" }}
        className="container-height-100"
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
        }}
      > 
        <Column
          width="5em"
          render={(value, dataTag) => (
          <Tooltip title={pinnedTags.includes(dataTag.tagId) ? "Remove from Favorites" : "Mark as Favorite"}>
            <StarFilled 
              className={`${pinnedTags.includes(dataTag.tagId) ? 'text-yellow-400' : 'text-gray-400 font-extrabold'} hover:text-yellow-500 cursor-pointer text-xl `}
              onClick={() => handlePinTag(dataTag.tagId)}
            />
          </Tooltip>
          )}
        />
        <Column
              width="6em"
              render={(value, dataTag, index) => (
                <>
                  <Tooltip title='Edit Data Classifier Tags'>
                    <Icon
                      disabled={!permission.canEdit}
                      color='blue'
                      name='pencil'
                      className="cursor-pointer"
                      onClick={() => onEditDataTag(dataTag.tagId)}
                    />
                  </Tooltip>
                  <Tooltip title='View Data Classifier Tags'>
                    <Icon
                      disabled={!permission.canView}
                      color='blue'
                      name='eye'
                      className="cursor-pointer"
                      onClick={() => onViewDataTag(dataTag.tagId)}
                    />
                  </Tooltip>
                  <Tooltip title='View Data Classifier History'>
                    <Icon
                      disabled={!permission.canView}
                      color='blue'
                      name='history'
                      className="cursor-pointer"
                      onClick={() => onViewDataTagHistory(dataTag.tagId)}
                    />
                  </Tooltip>
                </>
                )}
              />
        <Column
          width="15%"
          dataIndex="businessAreaName"
          title={
                <span>
                  Business Area {sortConfig.key === 'businessAreaName' && (sortConfig.direction === 'ascend' ? '↓':'↑' )}
                </span>
                }
          sorter
          sortOrder={sortConfig.key === 'businessAreaName' ? sortConfig.direction : null}
          onHeaderCell={() => ({
            onClick: () => setSortConfig({ key: 'businessAreaName', direction: sortConfig.direction === 'ascend' ? 'descend' : 'ascend' }),
                })}
          ellipsis={{
            showTitle: false,
          }}
          render={(value) =>  value === 'Unassigned' ? (
            <Tooltip title="This tag must be assigned to a business area before use">
              <span className="flex items-center gap-1 text-red-400 ">
                {value}
                <WarningOutlined className="text-red-500 text-xl" />
              </span>
            </Tooltip>
          ) : (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          )}
        />
      <Column
        width="10%"
        dataIndex="tagName"
        title={
          <span>
            Tag Name {sortConfig.key === 'tagName' && (sortConfig.direction === 'ascend' ? '↑' : '↓')}
          </span>
          }
        sorter
        sortOrder={sortConfig.key === 'tagName' ? sortConfig.direction : null}
        onHeaderCell={() => ({
          onClick: () => setSortConfig({ key: 'tagName', direction: sortConfig.direction === 'ascend' ? 'descend' : 'ascend' }),
        })}
        ellipsis={{
          showTitle: false,
        }}
        render={(value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
      />
    <Column
      width="20%"
      dataIndex="tagDescription"
      title="Tag Description"
      ellipsis={{
      showTitle: false,
      }}
      render={(value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
    />
    <Column
    width="20%"
    dataIndex="updatedDate"
    title={
      <span>
        Last Updated {sortConfig.key === 'updatedDate' && (sortConfig.direction === 'ascend' ? '↑' : '↓')}
      </span>
    }
    sorter
    sortOrder={sortConfig.key === 'updatedDate' ? sortConfig.direction : null}
    onHeaderCell={() => ({
      onClick: () => setSortConfig({ key: 'updatedDate', direction: sortConfig.direction === 'ascend' ? 'descend' : 'ascend' }),
    })}
    ellipsis={{
      showTitle: false,
    }}
    render={(value) => {
      const dateValue = moment.utc(value).toDate().toLocaleString();
      return <Tooltip placement="topLeft" title={dateValue}>{dateValue}</Tooltip>;
    }}
    />
    <Column
    width="18%"
    dataIndex="updatedByEmail"
    title="Updated By"
    ellipsis={{
      showTitle: false,
    }}
    render={(value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
    />
        <Column
              width="5%"
              render={(value, dataTag, index) => (
                <Tooltip title='Delete Data Classifier Tags'>
                  <Icon
                    disabled={!permission.canDelete}
                    color='blue'
                    name='trash alternate'
                    className="cursor-pointer"
                    onClick={() => onDeleteDataTag(dataTag)}
                  />
                </Tooltip>
              )}
              />
            </Table>}
                  footer={
                          <Row className="table-footer-row">
                              <Col span={24} className="footer-right-column">
                                  <Button type="primary"
                                      disabled={!permission.canAdd}
                                      onClick={onCreateNewDataTag}>
                                      <PlusOutlined /> Add Tag
                                  </Button>
                              </Col>
                          </Row>}
                      footerStyle={{ paddingTop: "1rem" }}>
                  </FullHeightContainerLayout>
              </Col>
          </Row>
        </>
      )}
    </div>
  );
};
export default DataTagViewer;