import { CloseCircleOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Spin, Upload, UploadFile } from "antd";
import Title from "antd/es/typography/Title";
import { showError, showSuccess } from "common/ToastNotifications";
import { ProhibitedArea } from "components";
import FormColorPickerField from "components/atoms/FormColorPickerField";
import PreviewTenancyCustomization from "components/organisms/PreviewTenancyCustomization";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import actions from "store/actions";
import CypressTestIds from "../cypress/CypressTestIds";

const { Dragger } = Upload;

const MIN_WIDTH = 200;
const MIN_HEIGHT = 200;
const MAX_WIDTH = 1024;
const MAX_HEIGHT = 1024;

interface TenancyCustomizationProps {
    customizationData: any;
    error: any;
    deleteCustomizationResult: any;
    createCustomizationResult: any;
    updateCustomizationResult: any;
    getTenancyCustomization: () => void;
    createTenancyCustomization: (customizationData: any) => void;
    updateTenancyCustomization: (customizationData: any) => void;
    deleteTenancyCustomization: () => void;
    user: any;
    tenant: any;
}

const TenancyCustomization: React.FC<TenancyCustomizationProps> = ({
    customizationData,
    error,
    deleteCustomizationResult,
    createCustomizationResult,
    updateCustomizationResult,
    getTenancyCustomization,
    createTenancyCustomization,
    updateTenancyCustomization,
    deleteTenancyCustomization,
    user,
    tenant,
}) => {
    const [updating, setUpdating] = useState(false);
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataAvail, setIsDataAvail] = useState(false);
    const [previewData, setPreviewData] = useState({
        organizationName: "",
        sloganFooter: "",
        colorHeaderFooter: "#E8E8E8",
        colorNavigationBar: "#1B1C1D",
        colorSelectedHeader: "#1890FF",
        colorPrimaryButtons: "#1677ff",
        colorForm: "#FFFFFF",
        colorSecondaryButtons: "#D1D5D8",
        colorSelectedRow: "#E6F4FF",
        colorLabel: "#000000",
        logo: null,
    });
    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        getTenancyCustomization();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            organizationName: tenant.company,
            sloganFooter: customizationData?.sloganFooter || "",
            colorHeaderFooter:
                customizationData?.colorHeaderFooter || "#E8E8E8",
            colorNavigationBar:
                customizationData?.colorNavigationBar || "#1B1C1D",
            colorSelectedHeader:
                customizationData?.colorSelectedHeader || "#1890FF",
            colorPrimaryButtons:
                customizationData?.colorPrimaryButtons || "#1677ff",
            colorSecondaryButtons:
                customizationData?.colorSecondaryButtons || "#D1D5D8",
            colorForm: customizationData?.colorForm || "#FFFFFF",
            colorSelectedRow: customizationData?.colorSelectedRow || "#E6F4FF",
            colorLabel: customizationData?.colorLabel || "#000000",
        });
        setIsDataAvail(!!customizationData);
        if (customizationData) {
            setImageUrl(customizationData.logo);
            setPreviewData({
                ...customizationData,
                organizationName: tenant.company,
            });
        }
    }, [
        customizationData,
        tenant,
        createCustomizationResult,
        updateCustomizationResult,
        form,
    ]);

    useEffect(() => {
        if (deleteCustomizationResult?.success) {
            form.resetFields();
            setImageUrl(null);
            setFileList([]);
            setIsModalOpen(false);
            setIsLoading(false);

            setPreviewData({
                organizationName: "",
                sloganFooter: "",
                colorHeaderFooter: "#E8E8E8",
                colorNavigationBar: "#1B1C1D",
                colorSelectedHeader: "#1890FF",
                colorPrimaryButtons: "#1677ff",
                colorForm: "#FFFFFF",
                colorSecondaryButtons: "#D1D5D8",
                colorSelectedRow: "#E6F4FF",
                colorLabel: "#000000",
                logo: null,
            });
        }
    }, [deleteCustomizationResult, form]);

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            return;
        }
        if (
            createCustomizationResult?.success ||
            updateCustomizationResult?.success
        ) {
            getTenancyCustomization();
            setUpdating(false);
            showSuccess("Update Successful");
        }
    }, [createCustomizationResult, updateCustomizationResult]);

    const handleFileValidation = (file: UploadFile): Promise<boolean> => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = URL.createObjectURL(file.originFileObj as Blob);

            img.onload = () => {
                const { width, height } = img;
                if (
                    width < MIN_WIDTH ||
                    height < MIN_HEIGHT ||
                    width > MAX_WIDTH ||
                    height > MAX_HEIGHT
                ) {
                    showError(
                        `Image dimensions should be between ${MIN_WIDTH}x${MIN_HEIGHT} and ${MAX_WIDTH}x${MAX_HEIGHT} pixels.`,
                    );
                    resolve(false);
                } else {
                    resolve(true);
                }
            };

            img.onerror = () => {
                showError(
                    "The image is not of type JPG and PNG. Please try a different image.",
                );
                resolve(false);
            };
        });
    };

    const handleRemove = useCallback(() => {
        setImageUrl(null);
        setFileList([]);
        form.setFieldsValue({ logo: null });
        setPreviewData((prev: any) => ({ ...prev, logo: null }));
    }, [form]);

    const onReset = useCallback(() => {
        form.resetFields();
        handleRemove();
        setPreviewData({
            organizationName: "",
            sloganFooter: "",
            colorHeaderFooter: "#E8E8E8",
            colorNavigationBar: "#1B1C1D",
            colorSelectedHeader: "#1890FF",
            colorPrimaryButtons: "#1677ff",
            colorForm: "#FFFFFF",
            colorSecondaryButtons: "#D1D5D8",
            colorSelectedRow: "#E6F4FF",
            colorLabel: "#000000",
            logo: null,
        });
    }, [form]);

    const handleUpdatePreview = () => {
        const currentFormValues = form.getFieldsValue(); // Get all current form values
        setPreviewData((prev) => ({
            ...prev,
            ...currentFormValues,
            logo: imageUrl,
        }));
    };

    const onResetData = () => {
        setIsLoading(true);
        deleteTenancyCustomization();
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        onResetData();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleFileChange = async (info: { fileList: UploadFile[] }) => {
        const file = info.fileList[0];
        const isValid = await handleFileValidation(file);
        if (isValid) {
            const url = URL.createObjectURL(file.originFileObj as Blob);
            setImageUrl(url);
            setFileList([file]);
            form.setFieldsValue({ logo: file });
        } else {
            setImageUrl(null);
            setFileList([]);
            form.setFieldsValue({ logo: null });
        }
    };

    const onFinish = (values: any) => {
        setUpdating(true);
        const submissionData = {
            ...values,
        };
        console.log(submissionData);
        if (!fileList.length) {
            values.logo = null; // Set logo to null if no image is selected
        } else {
            values.logo = fileList[0].originFileObj; // Otherwise, set it to the uploaded file
        }

        if (customizationData && customizationData.version != null) {
            submissionData.version = customizationData.version;
        }
        delete submissionData.organizationName;
        if (customizationData) {
            updateTenancyCustomization(submissionData);
        } else {
            createTenancyCustomization(submissionData);
        }
    };

    const getTenancyForm = useMemo(() => {
        return (
            <div className="flex flex-wrap overflow-y-scroll">
                <div className="bg-white p-10">
                    <div className="mb-8 flex justify-between">
                        <Title
                            level={2}
                            data-testid={
                                CypressTestIds.TENANCY_CUSTOMIZATION_TITLE_TEXT
                            }
                        >
                            Tenancy Customization
                        </Title>
                        <Button
                            type="default"
                            disabled={!isDataAvail}
                            onClick={showModal}
                            data-testid={
                                CypressTestIds.TENANCY_CUSTOMIZATION_RESET_BUTTON
                            }
                        >
                            Reset to Default
                        </Button>
                    </div>
                    <Form
                        form={form}
                        name="tenancyCustomization"
                        onFinish={onFinish}
                        layout="horizontal"
                        className="flex flex-col items-start"
                        initialValues={{
                            organizationName: tenant.company,
                            sloganFooter: customizationData?.sloganFooter || "",
                            colorHeaderFooter:
                                customizationData?.colorHeaderFooter ||
                                "#E8E8E8",
                            colorNavigationBar:
                                customizationData?.colorNavigationBar ||
                                "#1B1C1D",
                            colorSelectedHeader:
                                customizationData?.colorSelectedHeader ||
                                "#1890FF",
                            colorPrimaryButtons:
                                customizationData?.colorPrimaryButtons ||
                                "#1677FF",
                            colorSecondaryButtons:
                                customizationData?.colorSecondaryButtons ||
                                "#D1D5D8",
                            colorForm:
                                customizationData?.colorForm || "#FFFFFF",
                            colorSelectedRow:
                                customizationData?.colorSelectedRow ||
                                "#E6F4FF",
                            colorLabel:
                                customizationData?.colorLabel || "#000000",
                        }}
                    >
                        <Form.Item
                            label="My Organization's Name (Tenancy Name)"
                            name="organizationName"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please input your organization name!",
                                },
                            ]}
                        >
                            <Input
                                data-testid={
                                    CypressTestIds.TENANCY_CUSTOMIZATION_TEXT_INPUT_1
                                }
                                placeholder="Enter Organization Name"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Footer Slogan (null or blank allowed)"
                            name="sloganFooter"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input
                                data-testid={
                                    CypressTestIds.TENANCY_CUSTOMIZATION_TEXT_INPUT_2
                                }
                                placeholder="Enter Footer Slogan"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Corporate Logo"
                            name="logo"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="flex gap-4"
                        >
                            <div className="flex gap-4">
                                <div className="relative flex h-[160px] w-[160px] items-center justify-center overflow-hidden rounded-md border-2 border-dashed">
                                    {imageUrl ? (
                                        <>
                                            <img
                                                src={imageUrl}
                                                alt="Preview"
                                                data-testid={
                                                    CypressTestIds.TENANCY_CUSTOMIZATION_IMAGE_PREVIEW
                                                }
                                                className="h-full w-full object-cover"
                                            />
                                            <CloseCircleOutlined
                                                className="absolute right-2 top-2 cursor-pointer text-lg text-red-500"
                                                data-testid={
                                                    CypressTestIds.TENANCY_CUSTOMIZATION_DELETE_IMAGE_BUTTON
                                                }
                                                onClick={handleRemove}
                                            />
                                        </>
                                    ) : (
                                        <Dragger
                                            accept=".jpg,.jpeg,.png"
                                            multiple={false}
                                            beforeUpload={() => false}
                                            onChange={handleFileChange}
                                            fileList={fileList}
                                            showUploadList={false}
                                            className="flex h-full w-full items-center justify-center"
                                        >
                                            <div
                                                className="text-center"
                                                data-testid={
                                                    CypressTestIds.TENANCY_CUSTOMIZATION_IMAGE_INPUT
                                                }
                                            >
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Click or drag file to this
                                                    area to upload
                                                </p>
                                            </div>
                                        </Dragger>
                                    )}
                                </div>
                                <div>
                                    <p className="text-sm">
                                        Accepted file type: JPG, PNG
                                    </p>
                                    <p className="text-sm">
                                        (200x200 pixels - 1024x1024 pixels)
                                    </p>
                                </div>
                            </div>
                        </Form.Item>

                        <Title level={3} className="w-full py-[10px]">
                            Palette Selector for my organization's colors
                        </Title>

                        <div className="grid w-full grid-cols-2 gap-0">
                            <Form.Item
                                label="Header Footer"
                                name="colorHeaderFooter"
                                className="m-1"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName="value"
                                rules={[
                                    {
                                        pattern: /^#(?:[0-9a-fA-F]{6}){1,2}$/,
                                        required: true,
                                        message:
                                            "Please enter a valid Hexadecimal Value",
                                    },
                                ]}
                            >
                                <FormColorPickerField
                                    testName={
                                        CypressTestIds.TENANCY_CUSTOMIZATION_COLOR_PALETTE_1
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Navigation Bar"
                                name="colorNavigationBar"
                                className="m-1"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName="value"
                                rules={[
                                    {
                                        pattern: /^#(?:[0-9a-fA-F]{6}){1,2}$/,
                                        required: true,
                                        message:
                                            "Please enter a valid Hexadecimal Value",
                                    },
                                ]}
                            >
                                <FormColorPickerField
                                    testName={
                                        CypressTestIds.TENANCY_CUSTOMIZATION_COLOR_PALETTE_2
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Selected Header"
                                name="colorSelectedHeader"
                                className="m-1"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName="value"
                                rules={[
                                    {
                                        pattern: /^#(?:[0-9a-fA-F]{6}){1,2}$/,
                                        required: true,
                                        message:
                                            "Please enter a valid Hexadecimal Value",
                                    },
                                ]}
                            >
                                <FormColorPickerField
                                    testName={
                                        CypressTestIds.TENANCY_CUSTOMIZATION_COLOR_PALETTE_3
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Primary Buttons"
                                name="colorPrimaryButtons"
                                className="m-1"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName="value"
                                rules={[
                                    {
                                        pattern: /^#(?:[0-9a-fA-F]{6}){1,2}$/,
                                        required: true,
                                        message:
                                            "Please enter a valid Hexadecimal Value",
                                    },
                                ]}
                            >
                                <FormColorPickerField
                                    testName={
                                        CypressTestIds.TENANCY_CUSTOMIZATION_COLOR_PALETTE_4
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Form Background"
                                name="colorForm"
                                className="m-1"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName="value"
                                rules={[
                                    {
                                        pattern: /^#(?:[0-9a-fA-F]{6}){1,2}$/,
                                        required: true,
                                        message:
                                            "Please enter a valid Hexadecimal Value",
                                    },
                                ]}
                            >
                                <FormColorPickerField
                                    testName={
                                        CypressTestIds.TENANCY_CUSTOMIZATION_COLOR_PALETTE_5
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Secondary Buttons"
                                name="colorSecondaryButtons"
                                className="m-1"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName="value"
                                rules={[
                                    {
                                        pattern: /^#(?:[0-9a-fA-F]{6}){1,2}$/,
                                        required: true,
                                        message:
                                            "Please enter a valid Hexadecimal Value",
                                    },
                                ]}
                            >
                                <FormColorPickerField
                                    testName={
                                        CypressTestIds.TENANCY_CUSTOMIZATION_COLOR_PALETTE_6
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Selected Row"
                                name="colorSelectedRow"
                                className="m-1"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName="value"
                                rules={[
                                    {
                                        pattern: /^#(?:[0-9a-fA-F]{6}){1,2}$/,
                                        required: true,
                                        message:
                                            "Please enter a valid Hexadecimal Value",
                                    },
                                ]}
                            >
                                <FormColorPickerField
                                    testName={
                                        CypressTestIds.TENANCY_CUSTOMIZATION_COLOR_PALETTE_7
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Label Color"
                                name="colorLabel"
                                className="m-1"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName="value"
                                rules={[
                                    {
                                        pattern: /^#(?:[0-9a-fA-F]{6})$/,
                                        required: true,
                                        message:
                                            "Please enter a valid Hexadecimal Value",
                                    },
                                ]}
                            >
                                <FormColorPickerField
                                    testName={
                                        CypressTestIds.TENANCY_CUSTOMIZATION_COLOR_PALETTE_8
                                    }
                                />
                            </Form.Item>
                        </div>

                        <div className="mt-8 flex w-full flex-row justify-end gap-3">
                            <Button
                                type="primary"
                                className="!h-[50px] w-[132px] rounded-none"
                                data-testid={
                                    CypressTestIds.TENANCY_CUSTOMIZATION_SUBMIT_BUTTON
                                }
                                htmlType="submit"
                                loading={updating}
                            >
                                Save
                            </Button>
                            <Button
                                htmlType="reset"
                                className="!h-[50px] w-[132px] rounded-none bg-gray-300"
                                onClick={onReset}
                            >
                                Cancel
                            </Button>
                        </div>

                        {error && (
                            <p style={{ color: "red" }}>{error.message}</p>
                        )}
                    </Form>
                </div>
                <div>
                    <PreviewTenancyCustomization
                        previewData={previewData}
                        handleUpdatePreview={handleUpdatePreview}
                    />
                </div>
            </div>
        );
    }, [
        imageUrl,
        fileList,
        previewData,
        updating,
        error,
        form,
        onReset,
        handleRemove,
        isDataAvail,
        tenant.company,
        createTenancyCustomization,
        customizationData,
        updateTenancyCustomization,
    ]);

    return (
        <>
            {user.userType !== "ACCOUNT_ADMIN" ? (
                <ProhibitedArea></ProhibitedArea>
            ) : (
                getTenancyForm
            )}

            <Modal
                title="Are you sure?"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>
                    Warning: This will reset the data, and all existing data
                    will not be retrievable.
                </p>
                {isLoading && <Spin className="text-center" />}
            </Modal>
        </>
    );
};

const mapStateToProps = (state: {
    tenancyCustomization: {
        customizationData: any;
        error: any;
        deleteCustomizationResult: any;
        createCustomizationResult: any;
        updateCustomizationResult: any;
    };
    userLogin: {
        user: any;
        tenant: any;
    };
}) => ({
    customizationData: state.tenancyCustomization.customizationData,
    error: state.tenancyCustomization.error,
    deleteCustomizationResult:
        state.tenancyCustomization.deleteCustomizationResult,
    createCustomizationResult:
        state.tenancyCustomization.createCustomizationResult,
    updateCustomizationResult:
        state.tenancyCustomization.updateCustomizationResult,
    tenant: state.userLogin.tenant,
    user: state.userLogin.user,
});

const mapDispatchToProps = (dispatch: any) => ({
    getTenancyCustomization: () =>
        dispatch(actions.getTenancyCustomizationRequest()),
    createTenancyCustomization: (data: any) =>
        dispatch(actions.createTenancyCustomizationRequest(data)),
    updateTenancyCustomization: (data: any) =>
        dispatch(actions.updateTenancyCustomizationRequest(data)),
    deleteTenancyCustomization: () =>
        dispatch(actions.deleteTenancyCustomizationRequest()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TenancyCustomization);
